/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CalendarDataDto { 
    id?: number;
    purpose: CalendarDataDto.PurposeEnum;
    mrpComplianceEntryId?: number;
    activityChecklistItemId?: number;
    eventTitle?: string;
    eventDescription?: string;
    eventStart?: string;
    eventEnd?: string;
    eventLocation?: string;
}
export namespace CalendarDataDto {
    export type PurposeEnum = 'EVENT' | 'SPRINT' | 'PROJECT';
    export const PurposeEnum = {
        Event: 'EVENT' as PurposeEnum,
        Sprint: 'SPRINT' as PurposeEnum,
        Project: 'PROJECT' as PurposeEnum
    };
}


