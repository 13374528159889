/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface QuantitativeProcessMeasureDto { 
    id?: number;
    name: string;
    description: string;
    unitOfMeasure: string;
    type: QuantitativeProcessMeasureDto.TypeEnum;
    code?: string;
    systemDefined?: boolean;
    wasteType?: QuantitativeProcessMeasureDto.WasteTypeEnum;
}
export namespace QuantitativeProcessMeasureDto {
    export type TypeEnum = 'WASTE' | 'QUALITY' | 'GENERAL';
    export const TypeEnum = {
        Waste: 'WASTE' as TypeEnum,
        Quality: 'QUALITY' as TypeEnum,
        General: 'GENERAL' as TypeEnum
    };
    export type WasteTypeEnum = 'TIME' | 'INVENTORY' | 'MOTION' | 'WAITING' | 'OVER_PRODUCTION' | 'OVER_PROCESSING' | 'DEFECT';
    export const WasteTypeEnum = {
        Time: 'TIME' as WasteTypeEnum,
        Inventory: 'INVENTORY' as WasteTypeEnum,
        Motion: 'MOTION' as WasteTypeEnum,
        Waiting: 'WAITING' as WasteTypeEnum,
        OverProduction: 'OVER_PRODUCTION' as WasteTypeEnum,
        OverProcessing: 'OVER_PROCESSING' as WasteTypeEnum,
        Defect: 'DEFECT' as WasteTypeEnum
    };
}


