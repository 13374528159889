/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MilestonePojoMini { 
    id?: number;
    name?: string;
    startDate?: string;
    dueDate?: string;
    status?: MilestonePojoMini.StatusEnum;
}
export namespace MilestonePojoMini {
    export type StatusEnum = 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE';
    export const StatusEnum = {
        NotStarted: 'NOT_STARTED' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Done: 'DONE' as StatusEnum
    };
}


