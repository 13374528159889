import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchHandler} from "../../../../../shared/search/search-handler";
import {
    KeyResultControllerService,
    KeyResultPojo, LeadMeasureControllerService,
    LeadMeasurePojo, NameCodeIdPojo, NameIdPojo, QueryResultsKeyResultPojo,
    SearchKeyResultRequestParams, SoftwareProductionUnitPojo
} from "../../../../../../../sdk/customer-fulfillment-api-sdk";
import {SearchFilterSource} from "../../../../../shared/search/search-filter-source";
import {FormBuilder, Validators} from "@angular/forms";
import {AlertType} from "../../../../extranet/report-issue/report-issue.component";
import {SearchManager} from "../../../../../shared/search/search-manager";
import {HelperService} from "../../../../../services/helper.service";
import {Router} from "@angular/router";
import {PlatformSelectorService} from "../../../../../services/platform-selector.service";
import {PageManager} from "../../../../../services/page-manager";
import {NameValuePair} from "../../../../../models/etc/name-value-pair.model";
import {PaginatedSearch} from "../../../../../shared/search/paginated-search";
import {Observable} from "rxjs";
import {ViewPuDialogComponent} from "../view-pu-dialog/view-pu-dialog.component";

@Component({
    selector: 'app-mrp-uat-edit-schedule-search',
    templateUrl: './mrp-uat-edit-schedule-search.component.html',
    styleUrls: ['./mrp-uat-edit-schedule-search.component.css']
})
export class MrpUatEditScheduleSearchComponent implements OnInit,
    SearchHandler<KeyResultPojo, SearchKeyResultRequestParams>,
    SearchFilterSource<SearchKeyResultRequestParams> {

    form = this.fb.group({
        keyword: [''],
        platformId: ['', Validators.required]
    })

    @Input()
    uatSchedules: UATSchedule[] = [];

    @Output()
    showAlertMessage: EventEmitter<{ msg: string; type: AlertType }> = new EventEmitter<any>();

    @Output()
    editSchedule: EventEmitter<number> = new EventEmitter<number>();

    searchManager!: SearchManager<LeadMeasurePojo, SearchKeyResultRequestParams>;

    constructor(private fb: FormBuilder,
                public helperService: HelperService,
                private platformSelectorService: PlatformSelectorService,
                private pageManager: PageManager,
                private keyResultService: KeyResultControllerService) {
        this.searchManager = new SearchManager<KeyResultPojo, SearchKeyResultRequestParams>(this, this)
    }

    ngOnInit() {
        this.initListeners();
        this.getData(1);
    }

    initListeners(): void {
        this.platformSelectorService.currentPlatform.subscribe(value => {
            if (value) {
                this.form.patchValue({
                    platformId: value.toString()
                })
                this.submit();
            }
        })
    }

    deleteKeyResult(index: number): void {
        this.uatSchedules.splice(index, 1);
        this.getData(1);
    }

    getFilter(): SearchKeyResultRequestParams {
        return Object.assign({...this.form.getRawValue()});
    }

    getPersistentKey(): string {
        return 'MrpUatEditScheduleSearchComponent';
    }

    getSearchDescriptor(e: SearchKeyResultRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    updateSearchQuery(): void {
        this.pageManager.storeData(this.getPersistentKey(), 'search', this.form.value);
    }

    submit(): void {
        if (!this.form?.valid) {
            return;
        }
        this.updateSearchQuery();
        this.searchManager.reloadAndShowFirstPage();
    }

    private loadSearchData(): void {
        const data = this.pageManager.getData(this.getPersistentKey(), 'search');

        const cleanData = PaginatedSearch.removeNullFields(data);

        if (cleanData) {
            this.form.patchValue(cleanData);
        }
    }

    search(page: number | undefined, filter?: SearchKeyResultRequestParams): Observable<QueryResultsKeyResultPojo> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
        const paginatedSearch = PaginatedSearch.createCleanFilter(
            filter,
            this.searchManager.itemsPerPage,
            offset
        );
        return this.keyResultService.searchKeyResult({
            ...paginatedSearch.getSearchParams(), platformId: Number(this.form.controls.platformId.value)
        });
    }

    filter(): void {
        const keyword = this.form.controls.keyword.value;
        this.getData(1, keyword);
    }


    checklistPage = 1;
    itemsPerPage = 10;
    scheduleView: UATSchedule[] = [];

    getData(p = 1, keyword = ''): number {
        this.checklistPage = p;
        const data = this.uatSchedules?.filter((schedule, index) => {

            if (keyword) {
                const uatName = schedule.uatName?.toLowerCase();
                const sprintName = schedule.sprintName?.toLowerCase();
                return uatName.includes(keyword?.toLowerCase()) || sprintName.includes(keyword);
            } else {
                return true;
            }
        });
        const spliced = data.slice(0, p * this.itemsPerPage);
        spliced.splice(0, p * this.itemsPerPage - this.itemsPerPage);
        this.scheduleView = spliced;
        return this.checklistPage;
    }

    edit(index: number) {
        this.editSchedule.emit(index);
    }

    remove(index: number) {
        this.uatSchedules.splice(index, 1);
        this.getData(1);
    }

    viewProductionUnits(productionUnitPojos: Array<NameCodeIdPojo> | undefined): void {

        if (productionUnitPojos == undefined) {
            return;
        }

        const productionUnitIds = productionUnitPojos.map(x => x.id);

        this.helperService.loadModal(ViewPuDialogComponent, 'Production Units', '',
            {
                productionUnitIds: productionUnitIds,
            },
            '800px')
    }

}

export interface UATSchedule {
    uatName: string;
    uatId?: number;
    useCaseSet: NameIdPojo[],
    //name of pu, code - date of release, id - id of pu
    puSet: NameCodeIdPojo[],
    uatScheduledDate: string,
    sprintName: string,
    sprintStartDate: string,
    sprintEndDate: string;
    wigId: string,
}