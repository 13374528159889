// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  preProduction: false,
  apiBaseUrl: '/api',
  siteUrl: 'https://support.byteproducts.com/api',
  brandName: 'CFS Reference',
  keycloakConfig: {
    clientId: 'cfs-frontend',
    realm: 'cfs',
    url: 'https://support.byteproducts.com/auth',
  },
  sessionTimeout: 1440,
  defaultCountry: 'NGA',
  recaptcha: {
    siteKey: '6LcaD7soAAAAACRHrPdsCM8BtR3Ax7GW5v7_sWNt'
  },
  dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
  timeFormat: 'HH:mm:ss',
  dateFormat: 'YYYY-MM-DD',
  qaProcessCode: 'TESTING',
  requirementDocumentationProcessCode: 'RD',
  requirementDocumentationApprovalProcessCode: 'RA',
  ciProcessCode: 'CI',
  designProcessCode: 'DESIGN',
  releaseProcessCode: 'RELEASE',
  UATProcessCode: 'UAT',
  monitoringProcessCode: 'MONITORING',
  bwAccountCode: "CFSACC000002",
  fourDXProcessCode: "4DX",
  productionStandardProcessCode: 'PSTD',
  mrpProcessCode: 'MRP',
  designApprovalProcessCode: 'DESIGNA',
  mrpApprovalProcessCode: 'MRPA',
  productionProcessProcessCode: 'PP'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
