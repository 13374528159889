import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {
    ProductionUnitControllerService,
    SearchBusinessRequirementRequestParams,
    SearchSoftwareProductionUnitRequestParams, SoftwareModulePojo,
    SoftwareProductionUnitPojo
} from "../../../../../../../sdk/customer-fulfillment-api-sdk";
import {Observable} from "rxjs";
import {QueryResults} from "../../../../../shared/search/query-results";
import {PaginatedSearch} from "../../../../../shared/search/paginated-search";
import {NameValuePair} from "../../../../../models/etc/name-value-pair.model";
import {SearchHandler} from "../../../../../shared/search/search-handler";
import {SearchFilterSource} from "../../../../../shared/search/search-filter-source";
import {FormBuilder, Validators} from "@angular/forms";
import {HelperService} from "../../../../../services/helper.service";
import {PageManager} from "../../../../../services/page-manager";
import {SearchManager} from "../../../../../shared/search/search-manager";
import {AlertType} from "../../../../extranet/report-issue/report-issue.component";

@Component({
    selector: 'app-view-pu',
    templateUrl: './view-pu.component.html',
    styleUrls: ['./view-pu.component.css']
})
export class ViewPuComponent implements OnInit, SearchHandler<SoftwareProductionUnitPojo, SearchSoftwareProductionUnitRequestParams>,
    SearchFilterSource<SearchSoftwareProductionUnitRequestParams> {

    form = this.fb.group({
        platformId: [''],
        keyword: [''],
        ids: [[] as Array<number>, Validators.required]
    });

    searchManager!: SearchManager<SoftwareProductionUnitPojo, SearchSoftwareProductionUnitRequestParams>;

    @Input()
    productionUnitIds: Array<number>

    @Input()
    showReleaseStatus = true;

    @Output()
    showAlertMessage: EventEmitter<{ msg: string; type: AlertType }> = new EventEmitter<any>();

    @Input()
    showFilter = true;

    maxPageSize = 2;

    constructor(private productionUnitControllerService: ProductionUnitControllerService,
                private fb: FormBuilder,
                public helperService: HelperService,
                private pageManager: PageManager) {
        this.searchManager = new SearchManager<SoftwareModulePojo, SearchSoftwareProductionUnitRequestParams>(this, this);

    }

    ngOnInit(): void {
        this.updateFormData();
        this.submit();
    }


    updateFormData(): void {
        this.form.patchValue({
            ids: this.productionUnitIds
        })
    }

    submit(): void {
        if (this.form.invalid) {
            this.helperService.getInvalidFormFields(this.form);
            return;
        }
        this.updateSearchQuery();
        this.searchManager.reloadAndShowFirstPage();
    }

    updateSearchQuery(): void {
        this.pageManager.storeData(this.getPersistentKey(), 'search', this.form.value);
    }

    getFilter(): SearchSoftwareProductionUnitRequestParams {
        const form = this.form.getRawValue();
        const data = Object.assign(form);
        data.platformId = Number(form.platformId)
        return data;
    }

    search(
        page: number | undefined,
        filter?: SearchSoftwareProductionUnitRequestParams
    ): Observable<QueryResults<SoftwareProductionUnitPojo>> {

        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
        const paginatedSearch = PaginatedSearch.createCleanFilter(
            filter,
            this.searchManager.itemsPerPage,
            offset
        );
        return this.productionUnitControllerService.searchSoftwareProductionUnit({
            ...paginatedSearch.getSearchParams()
        });
    }

    getPersistentKey(): string {
        return 'ViewPuDialogComponent';
    }

    getSearchDescriptor(e: SearchBusinessRequirementRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

}
