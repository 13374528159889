/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ADTSchemaFieldPojo } from './adt-schema-field-pojo';
import { CalendarDataPojo } from './calendar-data-pojo';
import { AttachmentPojo } from './attachment-pojo';
import { ADTSchemaFieldDataPojo } from './adt-schema-field-data-pojo';


export interface WorkItemDefinitionADTPojo { 
    id?: number;
    adtSchemaType?: WorkItemDefinitionADTPojo.AdtSchemaTypeEnum;
    displayPositionXY?: string;
    label?: string;
    hasFields?: boolean;
    required?: boolean;
    calendarPurpose?: WorkItemDefinitionADTPojo.CalendarPurposeEnum;
    maxSizeInMb?: number;
    adtSchemaFields?: Array<ADTSchemaFieldPojo>;
    workItemDefinitionId?: number;
    workITemADTDataId?: number;
    workItemId?: number;
    labelDataValue?: string;
    file?: AttachmentPojo;
    adtSchemaFieldDataList?: Array<ADTSchemaFieldDataPojo>;
    calendarDataList?: Array<CalendarDataPojo>;
}
export namespace WorkItemDefinitionADTPojo {
    export type AdtSchemaTypeEnum = 'CHECKLIST' | 'DATE' | 'DATE_TIME' | 'TIME' | 'TEXT_AREA' | 'TEXT_FIELD' | 'URL' | 'CHECKBOX' | 'RADIO' | 'SELECT' | 'MULTI_SELECT' | 'NUMBER' | 'EMAIL_ADDRESS' | 'FILE' | 'TABLE' | 'CALENDAR';
    export const AdtSchemaTypeEnum = {
        Checklist: 'CHECKLIST' as AdtSchemaTypeEnum,
        Date: 'DATE' as AdtSchemaTypeEnum,
        DateTime: 'DATE_TIME' as AdtSchemaTypeEnum,
        Time: 'TIME' as AdtSchemaTypeEnum,
        TextArea: 'TEXT_AREA' as AdtSchemaTypeEnum,
        TextField: 'TEXT_FIELD' as AdtSchemaTypeEnum,
        Url: 'URL' as AdtSchemaTypeEnum,
        Checkbox: 'CHECKBOX' as AdtSchemaTypeEnum,
        Radio: 'RADIO' as AdtSchemaTypeEnum,
        Select: 'SELECT' as AdtSchemaTypeEnum,
        MultiSelect: 'MULTI_SELECT' as AdtSchemaTypeEnum,
        Number: 'NUMBER' as AdtSchemaTypeEnum,
        EmailAddress: 'EMAIL_ADDRESS' as AdtSchemaTypeEnum,
        File: 'FILE' as AdtSchemaTypeEnum,
        Table: 'TABLE' as AdtSchemaTypeEnum,
        Calendar: 'CALENDAR' as AdtSchemaTypeEnum
    };
    export type CalendarPurposeEnum = 'EVENT' | 'SPRINT' | 'PROJECT';
    export const CalendarPurposeEnum = {
        Event: 'EVENT' as CalendarPurposeEnum,
        Sprint: 'SPRINT' as CalendarPurposeEnum,
        Project: 'PROJECT' as CalendarPurposeEnum
    };
}


