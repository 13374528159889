import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchHandler} from "../../../../../../shared/search/search-handler";
import {
    ForecastDto, NameCodeIdPojo,
    ProductionUnitControllerService,
    ProductionUnitReleaseForecastDto,
    QueryResultsSoftwareProductionUnitPojo,
    ScoreboardControllerService,
    SearchSoftwareProductionUnitRequestParams,
    SoftwareProductionUnitPojo
} from "../../../../../../../../sdk/customer-fulfillment-api-sdk";
import {SearchFilterSource} from "../../../../../../shared/search/search-filter-source";
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {SearchManager} from "../../../../../../shared/search/search-manager";
import {PageManager} from "../../../../../../services/page-manager";
import {HelperService} from "../../../../../../services/helper.service";
import {PlatformSelectorService} from "../../../../../../services/platform-selector.service";
import {Router} from "@angular/router";
import {tap} from "rxjs/operators";
import {NameValuePair} from "../../../../../../models/etc/name-value-pair.model";
import {PaginatedSearch} from "../../../../../../shared/search/paginated-search";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {UnsubscribeOnDestroyAdapter} from "../../../../../../services/unsubscribe-on-destroy-adapter";
import moment from "moment";

@Component({
    selector: 'app-forecast-production-unit-search',
    templateUrl: './forecast-production-unit-search.component.html',
    styleUrls: ['./forecast-production-unit-search.component.css']
})
export class ForecastProductionUnitSearchComponent extends UnsubscribeOnDestroyAdapter implements OnInit,
    SearchHandler<SoftwareProductionUnitPojo, SearchSoftwareProductionUnitRequestParams>,
    SearchFilterSource<SearchSoftwareProductionUnitRequestParams> {

    form = this.fb.group({
        customerValuePlannedAcceptanceTime: [''],
        forecastArray: this.fb.array([]),
        wig: ['', Validators.required],

        searchForm: this.fb.group({
            platformId: ['', Validators.required],
            keyword: [''],
            customerValueId: ['',/* Validators.required*/],
            userAcceptanceTestId: [undefined as number, Validators.required],
            useCaseIds: [undefined as Array<number>]
        })
    })

    /**
     * map Production Unit id to FormGroup
     */
    formControlMap: Map<number, FormGroup> = new Map();

    searchManager!: SearchManager<SoftwareProductionUnitPojo, SearchSoftwareProductionUnitRequestParams>;

    maxPageSize = 5;

    submitting = false;

    @Input()
    label = 'Set Date for Production Unit';

    @Input()
    set minDate(date: any) {
     if (date) this.min = this.formatDate(date);
    }

    @Input()
    set maxDate(date: any) {
      if (date) this.max = this.formatDate(date);
    }

    min: string;
    max: string;

    constructor(private fb: FormBuilder,
                private pageManager: PageManager,
                public helperService: HelperService,
                private platformSelectorService: PlatformSelectorService,
                private productionUnitControllerService: ProductionUnitControllerService,
                private router: Router,
                private scoreboardService: ScoreboardControllerService) {
        super();
        this.searchManager = new SearchManager<SoftwareProductionUnitPojo, SearchSoftwareProductionUnitRequestParams>(this, this)
    }

    ngOnInit(): void {
        this.loadSearchData();
        this.initListeners();
    }


    getFilter(): SearchSoftwareProductionUnitRequestParams {
        return Object.assign({...this.form.controls.searchForm.getRawValue()});
    }

    getPersistentKey(): string {
        return 'ForecastProductionUnitSearchComponent';
    }

    getSearchDescriptor(e: SearchSoftwareProductionUnitRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    updateSearchQuery(): void {
        this.pageManager.storeData(this.getPersistentKey(), 'search', this.form.value);
    }

    submit(): void {
        if (!this.form?.controls.searchForm.valid) {
            return;
        }
        this.updateSearchQuery();
        this.searchManager.reloadAndShowFirstPage();
    }

    private loadSearchData(): void {
        const data = this.pageManager.getData(this.getPersistentKey(), 'search');

        const cleanData = PaginatedSearch.removeNullFields(data);

        if (cleanData) {
            // this.form.patchValue(cleanData);
        }
    }


    search(page: number | undefined, filter?: SearchSoftwareProductionUnitRequestParams): Observable<QueryResultsSoftwareProductionUnitPojo> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
        const paginatedSearch = PaginatedSearch.createCleanFilter(
            filter,
            this.searchManager.itemsPerPage,
            offset
        );
        return this.productionUnitControllerService.searchSoftwareProductionUnit({
            ...paginatedSearch.getSearchParams(),
            platformId: Number(this.form.controls.searchForm.controls.platformId.value)
        })
            .pipe(
                tap(
                    (result: QueryResultsSoftwareProductionUnitPojo): void => {
                        if (!result.empty) {
                            result.results.forEach(x => {
                                try {
                                    this.addForecastControlWithData(this.form.controls.searchForm.controls.customerValueId.value, x.id, x.currentPlannedCICompletionDate, x.useCaseIds);
                                } catch (error) {
                                    console.error('Error adding forecast control with data:', error);
                                }
                            });
                        }
                    }
                )
            );
    }

    save(): void {
        if (this.form.invalid) {
            (this.forecastControl.controls as Array<FormGroup>).forEach((group: FormGroup): void => {
                this.helperService.getInvalidFormFields(group)
            });

            if (!this.form.controls.wig.value) {

                this.showAlertMessage.emit({
                    msg: 'The specified customer value is not linked to a Wig',
                    type: AlertType.error
                })

                return;
            }

            this.showAlertMessage.emit({
                msg: 'Kindly fill in all required fields before proceeding',
                type: AlertType.error
            })
            console.log(this.helperService.getInvalidFormFields(this.form))
            
            return;
        }

        if (!this.forecastFormArrayIsDirty) {

            this.showAlertMessage.emit({
                msg: 'Add at least one Production Unit forecast before proceeding.',
                type: AlertType.error
            })

            return;
        }

        this.submitting = true;
        const form = this.form.getRawValue();
        const productionUnitReleaseForecasts = this.forecastFormArrayValue;

        const payload: ForecastDto = {
            customerValueId: Number(form.searchForm.customerValueId)
        }

        if (productionUnitReleaseForecasts && productionUnitReleaseForecasts.length > 0) {
            payload.productionUnitReleaseForecasts = productionUnitReleaseForecasts;
        }

        if (form.customerValuePlannedAcceptanceTime) {
            payload.customerValuePlannedAcceptanceTime = form.customerValuePlannedAcceptanceTime;
        }

        this.subscriptions.sink =
            this.scoreboardService.makeForecast(
                {forecastDto: payload, wigId: Number(form.wig)}
            )
                .subscribe({
                    next: (data) => {
                    },
                    complete: () => {
                        this.showAlertMessage.emit({msg: 'Forecasted!', type: AlertType.success});
                        this.clearFormControlMap();
                        this.clearForecastControl();
                        this.submitting = false;
                        this.submit();
                    },
                    error: (error: unknown) => {
                        this.showAlertMessage.emit({msg: this.helperService.getError(error), type: AlertType.error});
                        this.submitting = false;
                    }
                });
    }

    get forecastFormArrayIsDirty(): boolean {
        return this.forecastFormArrayValue?.length > 0
    }

    get forecastFormArrayValue(): Array<ProductionUnitReleaseForecastDto> {
        return this.forecastControl.value
            .map((x): ProductionUnitReleaseForecastDto => {
                return {
                    currentPlannedCICompletionDate: x.date ? moment(x.date).format('YYYY-MM-DD') : undefined,
                    softwareProductionUnitId: x.id
                }
            });
    }

    get forecastUpdatedFormArrayIsDirty(): boolean {
        return this.forecastUpdatedFormArrayValue?.length > 0
    }

    get forecastUpdatedFormArrayValue(): Array<ProductionUnitReleaseForecastDto> {

        return this.forecastControl.value
            .filter((x) => x.date)
            .filter((x) => x.date !== x.oldDate)
            .map((x): ProductionUnitReleaseForecastDto => {
                return {
                    currentPlannedCICompletionDate: moment(x.date).format('YYYY-MM-DD'),
                    softwareProductionUnitId: x.id
                }
            });
    }

    initListeners(): void {
        this.platformSelectorService.currentPlatform.subscribe(value => {
            if (value) {
                this.form.controls.searchForm.patchValue({
                    platformId: value.toString()
                })
                this.submit();
            }
        })
    }


    ///////////////////////////////
    ///FORECAST CONTROL
    ///////////////////////////////
    get forecastControl(): FormArray {
        return this.form.controls.forecastArray as FormArray;
    }

    removeForecastControl(i: AbstractControl<any>) {
        const index = this.forecastControl.controls.indexOf(i);
        this.forecastControl.removeAt(index)

    }

    addForecastControlWithData(customerValueId: string, id: number, date: string, useCases: any): void {

        if (this.formControlMap.has(id)) {
            return;
        }

        const forecastControl = this.fb.group({
            customerValueId: [customerValueId, this.customerValueIdV],
            id: [id, this.forecastV],
            oldDate: [date || ''],
            date: [date || ''],
            useCaseIds: [useCases]
        })

        this.forecastControl.push(forecastControl);
        this.formControlMap.set(id, forecastControl);
    }

    clearForecastControl(): void {
        this.forecastControl.clear({emitEvent: false});
    }

    clearFormControlMap(): void {
        this.formControlMap.clear();
    }

    forecastV: ValidatorFn = Validators.compose([]);

    customerValueIdV: ValidatorFn = Validators.compose([]);


    ///////////////////////////////////
    ///END FORECAST CONTROL
    ///////////////////////////////////

    getFormGroup(item: AbstractControl<any>): FormGroup {
        return item as FormGroup;
    }

    getFormGroupIndex(id: number): number {
        const formGroup = this.formControlMap.get(id);

        if (formGroup) {
            return this.forecastControl.controls.indexOf(formGroup);
        }

        throw new Error('Form Group not found');
    }

    /////////////////////
    //////ALERT
    /////////////////////

    @Output()
    showAlertMessage: EventEmitter<{ msg: string; type: AlertType }> = new EventEmitter<any>();

    /////////////////////
    //////END ALERT
    /////////////////////

    private formatDate(date: Date): string {
        return date?.toISOString().split('T')[0];
    }

    setForecastDates(puSet: NameCodeIdPojo[]) {
        puSet.forEach(pu => {
            this.addForecastControlWithData(this.form.controls?.searchForm?.controls?.customerValueId?.value, pu.id, pu.code, []);
        });
    }

    updateUseCaseIds(useCaseIds: number[]) {
        this.form.controls.searchForm.controls.useCaseIds.setValue(useCaseIds as any[]);
    }

    updateProductionUnitsForSelectedUseCases(useCaseIds: number[]): void {

        const keysToRemove = new Set<number>();

        for (const [key, value] of this.formControlMap) {
            const _useCaseIds: number[] = value.get("useCaseIds").value;

            if (_useCaseIds.every(useCaseId => !useCaseIds.includes(useCaseId))) {
                keysToRemove.add(key);
            }
        }

        keysToRemove.forEach(key => {
            this.removeForecastControl(this.formControlMap.get(key));
            this.formControlMap.delete(key)
        });
    }

    getControlDateValue(id: number): string {
        return this.forecastControl.controls[this.getFormGroupIndex(id)]?.get('date')?.value
    }
}
