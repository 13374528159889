<div>
    <form [formGroup]="form.controls.searchForm" (ngSubmit)="submit()">
        <div class="mb-3 mt-5 d-flex justify-content-between align-items-center">
            <div class="fw-bold fs-14 text-decoration-underline">{{ label }}</div>

            <div class="">
                <input type="text" formControlName="keyword" id="keyword"
                       class="form-control"
                       placeholder="...Search Request Keyword">
            </div>
        </div>
    </form>

    <div *ngIf="searchManager.totalCount > 0 " class="row"
         [ngClass]="{'disabled-table': searchManager.working}">
        <div class="d-md-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <app-total-records [searchManager]="searchManager"></app-total-records>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
                <page-size
                        *ngIf="searchManager.totalCount > 0"
                        [searchManager]="searchManager"
                ></page-size>
            </div>
        </div>
    </div>
    <form [formGroup]="form">
        <div class="card search" style="border: 0.2px solid #ececec;">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">Module</th>
                            <th scope="col">Production Unit Type</th>
                            <th scope="col">Production Unit Name</th>
                            <th style="width: 20%" scope="col">Set Release Date</th>
                        </tr>
                        </thead>

                        <ng-container *ngIf="!searchManager._working; else stillLoading">
                            <ng-container *ngIf="searchManager.list.length > 0; else NoDataToDisplay">
                                <tbody formArrayName="forecastArray">
                                <tr
                                        class="justify-content-center"
                                        *ngFor="let item of searchManager.list; let i = index"
                                        [formGroupName]="getFormGroupIndex(item?.id)">
                                    <td>{{ i + searchManager.offset + 1 }}</td>
                                    <td>{{ item?.softwareModule?.name | titlecase }}</td>
                                    <td>{{ item?.softwareUnitType?.name | uppercase }}</td>
                                    <td>{{ helperService.cutText(item?.name, 50) }}
                                        <span *ngIf="item?.name?.length >= 51" [placement]="'bottom'"
                                              [tooltip]="item?.name">
                                           <span class="iconify text-black pointer"
                                                 data-icon="material-symbols:info"> </span>
                                       </span>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center gap-3">

                                            <div class="datepicker-container pointer">
                                                <input #input type="date"
                                                       [formControlName]="'date'"
                                                       [min]="min"
                                                       [max]="max"
                                                >
                                                <img class="pointer"
                                                     src="/assets/img/calender.svg" style="cursor: pointer;">
                                            </div>
                                            <div (click)="input.showPicker()" class="pointer" [ngClass]="{'text-danger':!getControlDateValue(item.id)}">
                                                {{ getControlDateValue(item.id) ? (getControlDateValue(item.id) | date) : 'No Date Set' }}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </ng-container>
                        </ng-container>
                    </table>
                </div>
            </div>
        </div>

    </form>

    <div
            *ngIf="searchManager.totalCount > 10"
            class="row py-2 mt-4"
            [ngClass]="{ 'disabled-table': searchManager.working }"
    >
        <div class="col-12 d-flex justify-content-end">
            <pagination
                    [(ngModel)]="searchManager.page"
                    [boundaryLinks]="true"
                    [itemsPerPage]="searchManager.itemsPerPage"
                    [maxSize]="maxPageSize"
                    [rotate]="true"
                    [totalItems]="searchManager.totalCount"
                    firstText="&laquo;"
                    lastText="&raquo;"
                    nextText="&rsaquo;"
                    previousText="&lsaquo;"
            ></pagination>
        </div>
    </div>
</div>


<ng-template #stillLoading>
    <tr>
        <td colspan="100%">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </td>
    </tr>
</ng-template>

<ng-template #NoDataToDisplay>
    <tr>
        <td class="text-center" colspan="100%">NO DATA</td>
    </tr>
</ng-template>
