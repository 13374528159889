<div>
    <app-user-card-header></app-user-card-header>

    <hr class="px-2 mb-3"/>
    <form [formGroup]="form" class="mb-5 mt-4">
        <div class="row mb-4">
            <div class="col-12 mb-3">
                <label class="form-label required">Sprint Type</label>
                <div class="text-center py-1 px-4 rounded-2 mb-2" style="border: 1px solid; width: fit-content; margin: 0 auto;">
                    <mat-radio-group
                            formControlName="sprintType"
                            aria-label="Select category">
                        <mat-radio-button [color]="'primary'" [value]="true" name="sprintType">New Sprint</mat-radio-button>
                        <mat-radio-button [color]="'primary'" [value]="false" name="sprintType">Existing Sprint</mat-radio-button>
                    </mat-radio-group>
                </div>

            </div>

            <div *ngIf="form.get('sprintType')?.value == true" class="col-6 mb-3">
                <label class="form-label required">Sprint Name</label>
                <input
                        formControlName="sprintName"
                        type="text"
                        class="form-control"
                        placeholder="--Enter Sprint Name--"
                />
                <error-message
                        [controlName]="'sprintName'"
                        [singularError]="true"
                        [form]="form">
                </error-message>
            </div>
            <div *ngIf="form.get('sprintType')?.value != true" class="col-6 mb-3">
                <label class="form-label required">Sprint Name</label>
                <select class="form-control form-select"
                        formControlName="sprintId">
                    <option readonly [value]="''">Select Sprint</option>
                    <option *ngFor="let m of sprints$ | async"
                            [value]="m.id">{{ helperService.cutText(m.name, 50) }}
                    </option>
                </select>
                <error-message
                        [controlName]="'sprintId'"
                        [singularError]="true"
                        [form]="form">
                </error-message>
            </div>

            <div class="col-6 mb-3">
                <label class="form-label">Wig</label>
                <select class="form-control form-select"
                        formControlName="wigId">
                    <option readonly [value]="''">Select Wig</option>
                    <option *ngFor="let m of wigs$ | async"
                            [value]="m.id">{{ helperService.cutText(m.name, 50) }}
                    </option>
                </select>
                <error-message
                        [controlName]="'wigId'"
                        [singularError]="true"
                        [form]="form">
                </error-message>
            </div>
            <div class="col-md-6 mb-3">
                <label class="form-label required">Start Date</label>
                <input
                        formControlName="startDate"
                        type="text"
                        class="form-control"
                        [placeholder]="_datePlaceHolder"
                        [bsConfig]="_datePickerConfig"
                        bsDatepicker
                />
                <error-message
                        [controlName]="'startDate'"
                        [singularError]="true"
                        [form]="form">
                </error-message>
            </div>
            <div class="col-md-6 mb-3">
                <label class="form-label required">End Date</label>
                <input formControlName="endDate" type="text"
                       class="form-control"
                       [placeholder]="_datePlaceHolder"
                       [minDate]="sprintStartDate()"
                       [bsConfig]="_datePickerConfig"
                       bsDatepicker
                />
                <error-message
                        [controlName]="'endDate'"
                        [singularError]="true"
                        [form]="form">
                </error-message>
            </div>

            <div class="px-2 mb-3">
                <hr/>
            </div>

            <div class="col-md-6 mb-3">
                <label class="form-label">UAT Name</label>
                <input
                        formControlName="uatName"
                        type="text"
                        class="form-control"
                        placeholder="--Enter UAT Name--"
                />
                <error-message
                        [controlName]="'uatName'"
                        [singularError]="true"
                        [form]="form">
                </error-message>
            </div>
            <div class="col-md-6 mb-3">
                <label class="form-label">UAT Scheduled Date</label>
                <input
                        formControlName="uatScheduledDate"
                        type="text"
                        class="form-control"
                        [placeholder]="_datePlaceHolder"
                        [minDate]="sprintStartDate()"
                        [maxDate]="sprintEndDate()"
                        [bsConfig]="_datePickerConfig"
                        bsDatepicker
                />
                <error-message
                        [controlName]="'uatScheduledDate'"
                        [singularError]="true"
                        [form]="form">
                </error-message>
            </div>
            <div class="col-md- mb-4">
                <label class="form-label">Use Case</label>
                <ng-multiselect-dropdown
                        [placeholder]="'--Select Use Cases--'"
                        [settings]="dropdownSettings"
                        [data]="useCases$ | async"
                        formControlName="useCase"
                >
                </ng-multiselect-dropdown>
                <error-message
                        [controlName]="'useCase'"
                        [singularError]="true"
                        [form]="form">
                </error-message>
            </div>

            <app-forecast-production-unit-search
                #forecastPUSearchComponent
                [label]="'Production Units'"
                [minDate]="sprintStartDate()"
                [maxDate]="uatDate()"
            />

            <div class="mt-3 mb-3 text-end">
                <button (click)="addUAT()" class="btn btn-secondary">
                    <i class="iconify icon" data-icon="octicon:plus-24"></i>
                    Add UAT
                </button>
            </div>

            <div class="px-2 mb-3">
                <hr/>
            </div>
            <app-mrp-uat-edit-schedule-search (editSchedule)="edit($event)"
                                              #uatEditComponent></app-mrp-uat-edit-schedule-search>
        </div>
    </form>

    <div class="mt-5 mb-3 text-end">
        <button [disabled]="stillLoading" (click)="submit()" class="btn btn-dark">
            <i class="iconify icon" data-icon="bi:save"></i>
            {{ saveButtonText || 'Save and Send Request' }}
        </button>
    </div>
</div>
