/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WorkItemDefinitionDto { 
    id?: number;
    itemType: WorkItemDefinitionDto.ItemTypeEnum;
    required: boolean;
    ordered?: boolean;
    label?: string;
    minimumNumberOfItems?: number;
    maximumNumberOfItems?: number;
    workProductSectionDefinitionId: number;
    workItemStatusGroupId?: number;
}
export namespace WorkItemDefinitionDto {
    export type ItemTypeEnum = 'ADT' | 'PRODUCTION_UNIT' | 'SERVICE_UNIT' | 'IMAGE';
    export const ItemTypeEnum = {
        Adt: 'ADT' as ItemTypeEnum,
        ProductionUnit: 'PRODUCTION_UNIT' as ItemTypeEnum,
        ServiceUnit: 'SERVICE_UNIT' as ItemTypeEnum,
        Image: 'IMAGE' as ItemTypeEnum
    };
}


