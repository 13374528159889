/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkItemDto } from './work-item-dto';


export interface WorkProductSectionDetailDto { 
    workProductSectionId?: number;
    workProductSectionDefinitionId: number;
    workItemType: WorkProductSectionDetailDto.WorkItemTypeEnum;
    workItemDefinitionId: number;
    sectionApproverPortalUserId?: number;
    workItems?: Array<WorkItemDto>;
}
export namespace WorkProductSectionDetailDto {
    export type WorkItemTypeEnum = 'ADT' | 'PRODUCTION_UNIT' | 'SERVICE_UNIT' | 'IMAGE';
    export const WorkItemTypeEnum = {
        Adt: 'ADT' as WorkItemTypeEnum,
        ProductionUnit: 'PRODUCTION_UNIT' as WorkItemTypeEnum,
        ServiceUnit: 'SERVICE_UNIT' as WorkItemTypeEnum,
        Image: 'IMAGE' as WorkItemTypeEnum
    };
}


