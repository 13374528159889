import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AsyncSubject, filter, from, mergeMap, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {KeycloakLoginOptions} from 'keycloak-js';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {ApiResponse} from '../models/etc/api-response';
import {User} from '../models/user/user.model';
import {UserAccount} from '../models/account/user-account.model';
import {Constant} from '../models/enum/constants';
import {PermissionType} from '../models/account/permission-type.enum';
import {environment} from '../../environments/environment';
import {PageManager} from './page-manager';
import {AccountMembershipPojo, AuditTrailControllerService} from "../../../sdk/customer-fulfillment-api-sdk";
import PermissionsEnum = AccountMembershipPojo.PermissionsEnum;


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public static _user: User;
  private static user: Subject<User | undefined> = new ReplaySubject(undefined);
  private static token: Subject<string | null> = new ReplaySubject(1);
  private static ongoingFetch: Observable<any> | null;
  private static initialized: boolean;
  public static _currentUserAccount: UserAccount | undefined;
  private static currentUserAccount$: Subject<UserAccount | null> = new ReplaySubject(undefined);
  private static newUserToken: EventEmitter<string | null> = new EventEmitter();
  public allAccounts!: UserAccount[];

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private keycloak: KeycloakService,
    private router: Router,
    private pageManager: PageManager,
    private loggerService: AuditTrailControllerService
  ) {
    AuthenticationService.user.subscribe((user: any) => {
      if (user === undefined) {
        AuthenticationService._currentUserAccount = undefined;
        AuthenticationService.currentUserAccount$.next(AuthenticationService._currentUserAccount!);
        return;
      }
      AuthenticationService.initialized = true;
      AuthenticationService._user = user;
      if (user?.accounts) {
        this.allAccounts = user.accounts.map((it: any) => new UserAccount(it));
        let firstBusinessAccount = this.allAccounts.find(
          (value) => value.accountType == 'ORGANIZATION'
        );
        let lastCreatedBusiness = null;
        if (sessionStorage.getItem('LAST_CREATED_ORG_ACC_CODE')) {
          lastCreatedBusiness = this.allAccounts.find(
            (value) => value.accountCode == sessionStorage.getItem('LAST_CREATED_ORG_ACC_CODE')
          );
          sessionStorage.removeItem('LAST_CREATED_ORG_ACC_CODE');
          firstBusinessAccount = lastCreatedBusiness || firstBusinessAccount;
        }
        let accInStorage = this.getCurrentUserAccountInStorage(user);
        if (!accInStorage) {
          accInStorage = this.allAccounts[0];
        }
        if (accInStorage && accInStorage.accountType != 'ORGANIZATION' && firstBusinessAccount) {
          accInStorage = firstBusinessAccount;
        }
        AuthenticationService._currentUserAccount = accInStorage;
        AuthenticationService.currentUserAccount$.next(AuthenticationService._currentUserAccount);
      }
    });
    this.keycloak.keycloakEvents$
      .pipe(
        filter(
          (value) =>
            value.type === KeycloakEventType.OnAuthSuccess ||
            value.type === KeycloakEventType.OnAuthRefreshSuccess ||
            value.type === KeycloakEventType.OnAuthRefreshError ||
            value.type === KeycloakEventType.OnTokenExpired
        ),
        mergeMap(() => from(this.keycloak.getToken()))
      )
      .subscribe((token: string | null) => {
        AuthenticationService.token.next(token);
      });
  }


  public getLastProtectedUrl(): string | null {
    return null;
  }


  public clearStaleSession(): void {
    const redirect = AuthenticationService._user;
    AuthenticationService.user.next(undefined);
    localStorage.clear();
    sessionStorage.clear();
    this.pageManager.clearAllData();
    if (redirect) {
      location.href = this.router.createUrlTree(['/']).toString();
    }
  }

  public logout(redirectUri?: string): Observable<void> {
    const STORAGE_KEY = 'isFirstLogin';
    localStorage.setItem(STORAGE_KEY, 'true');
    this.loggerService.logLogoutAction({ auditTrailDto: {} }).subscribe((res) => {
      this.clearStaleSession();
      return from(this.keycloak.logout(redirectUri)).pipe(
          tap((x) => AuthenticationService.user.next(undefined))
      );
    });
    return new Observable<void>();
  }

  public login(loginOptions: KeycloakLoginOptions): Promise<void> {
    return this.keycloak.login(loginOptions);
  }

  public getToken(): Subject<string | null> {
    return AuthenticationService.token;
  }

  public getMobileToken(): string {
    const token = this.cookieService.get('token');
    return token; //'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxZWM2MTAyNy0xMjUyLTQxMTItYTcwMi05OWY1YzZmZjA1NzIiLCJpc3MiOiIiLCJleHAiOjQ4Mzk4MTQzMTMsImlhdCI6MTY4NDE0MDcxM30.r73rpU5iIY0YhGhVNx1GawJTHX4qmFcjQIVnz48F6yI';
  }

  public getAccount(): Subject<UserAccount | null> {
    return AuthenticationService.currentUserAccount$;
  }

  private permissions(): string[] {
    const currentAccount = AuthenticationService._currentUserAccount;
    if (!currentAccount) {
      return [];
    }
    return currentAccount.permissions;
  }

  public setCurrentAccount(account: UserAccount): void {
    this.setCurrentUserAccount(account);
  }

  public hasPermission(permissionName: string | PermissionType | PermissionsEnum): boolean {
    return this.permissions().filter((it: string) => it === permissionName).length > 0;
  }

  public hasAnyPermission(permissions: string[] | PermissionType[] | PermissionsEnum[]): boolean {
    for (const permission of permissions) {
      if (this.hasPermission(permission)) {
        return true;
      }
    }
    return false;
  }

  hasRole(role: string): boolean {
    return AuthenticationService._currentUserAccount?.roles.find((value: string) => value?.toUpperCase() == role?.toLowerCase()) != null;
  }

  hasAnyRole(roles: string[]): boolean {
    for (const role of roles) {
      if (this.hasRole(role)) {
        return true;
      }
    }
    return false;
  }

  public hasAccountType(accountType: string): boolean {
    return AuthenticationService._currentUserAccount?.accountType === accountType;
  }

  public requestPasswordReset(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}/password/forgot`, data);
  }

  public getUser(): Subject<User | undefined> {
    return AuthenticationService.user;
  }

  public forbidAccess(): void {
    this.router.navigate(['/forbidden']);
  }

  public fetchUser(): Observable<User> {
    if (AuthenticationService.initialized) {
      return of(AuthenticationService._user);
    }
    return this.fetch();
  }

  // public resetPassword(data: any): Observable<any> {
  //   return this.httpClient.post(`${environment.authApiBaseUrl}/password/reset/${data.resetToken}`,
  //     {password: data.password},
  //     {responseType: 'text'});
  // }

  public changePassword(password: string): Observable<ApiResponse<string> | null> {
    const mapper = (response: HttpResponse<ApiResponse<string>>): ApiResponse<string> | null => {
      AuthenticationService.newUserToken.next(response.body && response.body.data);
      return response.body;
    };
    return this.httpClient
      .post<ApiResponse<string>>(
        `${environment.apiBaseUrl}/change-password`,
        {password},
        {observe: 'response'}
      )
      .pipe(map(mapper));
  }

  private fetch(): Observable<any> {
    const wrapper = new AsyncSubject();
    AuthenticationService.ongoingFetch = wrapper;
    // console.log('ongoingFetch set');

    this.httpClient.get(`${environment.apiBaseUrl}/me`).subscribe(
      (u: any) => {
        const user = new User(u);
        wrapper.next(user);
        wrapper.complete();

        AuthenticationService.user.next(user);
        AuthenticationService.ongoingFetch = null;
      },
      (err: unknown) => {
        wrapper.error(err);
        AuthenticationService.user.next(undefined);
      }
    );

    return AuthenticationService.ongoingFetch;
  }

  private getCurrentUserAccountFromStorage(): UserAccount | undefined {
    return this.pageManager.getData(
      'USER_ACCOUNT',
      'currentAccount',
      Constant.Storage.LOCAL
    ) as UserAccount;
  }

  public getCurrentAccount(): UserAccount | null {
    const data = this.pageManager.getData('USER_ACCOUNT', 'currentAccount');
    return data ? (data as UserAccount) : null;
  }

  private setCurrentUserAccount(userAccount: UserAccount): void {
    if (!userAccount) {
      AuthenticationService.currentUserAccount$.next(this.getCurrentUserAccountFromStorage()!);
    } else {
      AuthenticationService.currentUserAccount$.next(userAccount);
      this.pageManager.storeData(
        'USER_ACCOUNT',
        'currentAccount',
        userAccount,
        Constant.Storage.LOCAL
      );
    }
  }

  public loginAndNavigateToDashboard(): Promise<any> {
    return this.login({
      redirectUri: `${window.location.origin}/dashboard`
    });
  }

  private getCurrentUserAccountInStorage(user: User): UserAccount | null | undefined {
    const accountInStorage: UserAccount | undefined = this.getCurrentUserAccountFromStorage();
    if (!accountInStorage) {
      return null;
    }
    return this.allAccounts.find((value) => value.accountCode == accountInStorage.accountCode);
  }
}
