/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProcessDefinitionDto { 
    id?: number;
    versionNumberDisplayName: string;
    processModelId: number;
    processOwnerId: number;
    inputWorkProductDefinitionIds?: Array<number>;
    outputWorkProductDefinitionId: number;
    quantitativeProcessMeasureIds?: Array<number>;
    wasteMeasures?: Array<ProcessDefinitionDto.WasteMeasuresEnum>;
    approverRoles?: Array<number>;
    purposeAnnotations?: Array<ProcessDefinitionDto.PurposeAnnotationsEnum>;
}
export namespace ProcessDefinitionDto {
    export type WasteMeasuresEnum = 'TIME' | 'INVENTORY' | 'MOTION' | 'WAITING' | 'OVER_PRODUCTION' | 'OVER_PROCESSING' | 'DEFECT';
    export const WasteMeasuresEnum = {
        Time: 'TIME' as WasteMeasuresEnum,
        Inventory: 'INVENTORY' as WasteMeasuresEnum,
        Motion: 'MOTION' as WasteMeasuresEnum,
        Waiting: 'WAITING' as WasteMeasuresEnum,
        OverProduction: 'OVER_PRODUCTION' as WasteMeasuresEnum,
        OverProcessing: 'OVER_PROCESSING' as WasteMeasuresEnum,
        Defect: 'DEFECT' as WasteMeasuresEnum
    };
    export type PurposeAnnotationsEnum = 'USER_ACCEPTANCE' | 'QUALITY_ASSURANCE' | 'DOCUMENTATION';
    export const PurposeAnnotationsEnum = {
        UserAcceptance: 'USER_ACCEPTANCE' as PurposeAnnotationsEnum,
        QualityAssurance: 'QUALITY_ASSURANCE' as PurposeAnnotationsEnum,
        Documentation: 'DOCUMENTATION' as PurposeAnnotationsEnum
    };
}


