import {Inject, Injectable, Optional} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {Location} from '@angular/common';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParameterCodec} from "@angular/common/http";
import * as moment from "moment/moment";
import {DialogPosition, MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ConfirmComponent} from "../shared/components/dialogs/confirm/confirm.component";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {
    BASE_PATH,
    Configuration,
    UploadResponseProductionUnitUploadDtoProductionUnitUploadDto
} from "../../../sdk/customer-fulfillment-api-sdk";
import {CustomHttpParameterCodec} from "../../../sdk/customer-fulfillment-api-sdk/encoder";
import {debounceTime, map} from "rxjs/operators";
import {ComponentType} from "@angular/cdk/overlay";

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    public configuration = new Configuration();
    protected basePath = 'http://localhost:3281';
    public defaultHeaders = new HttpHeaders();
    public encoder: HttpParameterCodec;

    constructor(private location: Location,
                private matDialog: MatDialog,
                protected httpClient: HttpClient,
                @Optional() @Inject(BASE_PATH) basePath: string,
                @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    getInvalidFormFields(form: FormGroup): any[] {
        const invalidControls = [];

        for (const controlName in form.controls) {
            const control = form.controls[controlName];
            control.markAsTouched();
            if (control.disabled) continue;
            if (control.invalid) {
                invalidControls.push({
                    name: controlName,
                    errors: control.errors
                });
            }
        }
        return invalidControls;
    }

    cutText(name: string, chars: number): string {
        if (!name) {
            ('');
        }
        return name?.length > chars ? name.substr(0, chars) + '...' : name;
    }

    back(): void {
        this.location.back();
    }

    getFullName(data: any, cut = false, length = 15): string {
        console.log(data);
        const fullName = data?.firstName + ' ' + data?.otherNames + ' ' + data?.lastName;
        if (cut) {
            return this.cutText(fullName, length);
        }
        return fullName;
    }

    preventNumberInput(event: KeyboardEvent): void {
        const charCode = typeof event.which == 'undefined' ? event.keyCode : event.which;
        const charStr = String.fromCharCode(charCode);
        if (event?.code?.includes('Numpad')) {
            event?.preventDefault();
        } else if (/\d/.test(charStr)) {
            event?.preventDefault();
        }
    }

    getTodayDate(): string {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();

        return `${year}-${month}-${day}`;
    }

    public removeModalDialogClass(): void {
        const elements = document.getElementsByClassName('modal-dialog');

        if (elements.length > 0) {
            elements[0].classList.remove('modal-dialog');
        }
    }

    public hideOffCanvasClass(): void {
        const elements = document.getElementsByClassName('offcanvas');

        if (elements.length > 0) {
            elements[0].classList.remove('show');
        }
    }

    showOffCanvasClass(): void {
        const elements = document.getElementsByClassName('offcanvas');

        if (elements.length > 0) {
            elements[0].classList.add('show');
            console.log(elements);
        }
    }

    getError(error: any): string {
        if (
            error instanceof HttpErrorResponse &&
            error.error &&
            typeof error.error == 'object' &&
            error.error.message
        ) {
/*            if (error.error.message.indexOf(':') > -1) {
                return error.error.message.split(':')[1]
            }*/
            return error.error.message;
        }
        return 'Something went wrong. Please try again later.';
    }

    getDayDiffFromDateTillNow(date: string | undefined): number {
        const issuanceDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
        const currentDate = moment();
        return currentDate.diff(issuanceDate, 'days');
    }

    loadModal<T>(
        component: ComponentType<T>,
        title: string,
        subtitle = '',
        data: T | {} = {},
        width = '500px',
        minHeight?,
        maxHeight?,
    ) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {...data, modal: {title, subtitle}};
        dialogConfig.width = width;
        dialogConfig.minHeight = minHeight
        if(maxHeight) {
            dialogConfig.maxHeight = maxHeight;
        }
        dialogConfig.autoFocus = false;
        dialogConfig.enterAnimationDuration = 3;
        const dialogRef = this.matDialog.open(component, dialogConfig);
        return dialogRef;
    }

    confirm(title: string, description: string, data = {}) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            title: title,
            description: description,
            ...data
        };
        dialogConfig.width = '400px';
        dialogConfig.autoFocus = false;
        const dialogRef = this.matDialog.open(ConfirmComponent, dialogConfig);
        return dialogRef;
    }


    noWhitespaceValidator(control: FormControl): unknown {
        let isWhitespace: boolean;
        if (control.value == '') {
            isWhitespace = false;
        } else {
            isWhitespace = (control.value || '').trim().length === 0;
        }
        const isValid = !isWhitespace;
        return isValid ? null : {whitespace: true};
    }

    mustHaveNoValueValidator(control: FormControl): unknown {
        const isValid = control.value === ''
            || control.value == undefined;
        return isValid ? null : {notEmpty: true};
    }

    getFileUrl(fileId: number, download = true): string {
        return download
            ? `${environment.apiBaseUrl}/files/${fileId}`
            : `${environment.apiBaseUrl}/files/${fileId}/open`;
    }

    gotoFragment(fragment: string): void {
        const currentUrl = this.location.path();
        const newUrl = `${currentUrl}#${fragment}`;
        this.location.go(newUrl);
    }

    getFormErrorMessages(controlName: string, formGroup: FormGroup): string[] {
        const control = formGroup.get(controlName);
        const errors = control.errors;
        const errorMessages = [];
        controlName = this.toTitleCase(controlName);


        if (errors['required']) {
            errorMessages.push(`${controlName} is required`);
        }

        if (errors['minlength']) {
            errorMessages.push(`${controlName} must be at least ${errors['minlength'].requiredLength} characters long`);
        }

        if (errors['maxlength']) {
            errorMessages.push(`${controlName} cannot be more than ${errors['maxlength'].requiredLength} characters long`);
        }

        if (errors['pattern']) {
            errorMessages.push(`${controlName} contains invalid characters`);
        }

        if (errors['noWhitespaceValidator']) {
            errorMessages.push(`${controlName} cannot contain whitespace`);
        }
        if (errors['telePhoneNumberValidator']) {
            errorMessages.push(`${controlName} is not valid`);
        }
        if (errors['invalidPhoneNumber']) {
            errorMessages.push(`${controlName} is not valid`);
        }

        return errorMessages;
    }

    toTitleCase(controlName: string): string {
        // Split camel case words with space
        let result = controlName.replace(/([A-Z])/g, ' $1');

        // Convert the first letter of each word to uppercase and the rest to lowercase
        result = result.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

        return result;
    }

    /**
     * An array of years, from current year down to ${length = 5} years below
     * Can modify length as required
     */
    getYearsUntil(length = 5): number[]{
        const currentYear = new Date().getFullYear();
        return Array.from({ length: length }, (_, index) => currentYear - index);
    }

    /**
     * An array of years, from current year (exclusive) to ${length = 5} years above
     * Can modify length as required
     */
    getYearsAfter(length = 5): number[]{
        const currentYear = new Date().getFullYear();
        return Array.from({ length: length }, (_, index) => currentYear + index + 1);
    }

    /**
     * An array of all months in short form
     */
    getMonthsInShort(): string[]{
        return Array.from({ length: 12 }, (_, index) =>
            new Date(0, index).toLocaleString('en-US', { month: 'short' })
        );
    }

    /**
     * An array of all months in long form
     */
    getMonthsInFull(){
        return Array.from({ length: 12 }, (_, index) =>
            new Date(0, index).toLocaleString('en-US', { month: 'long' })
        );
    }

    uploadProductionUnit(
        data: FormData
    ): Observable<UploadResponseProductionUnitUploadDtoProductionUnitUploadDto> {
        return this.httpClient.post<UploadResponseProductionUnitUploadDtoProductionUnitUploadDto>(
            `${this.configuration.basePath}/software-production-unit/pu-upload`,
            data,
            {
                withCredentials: this.configuration.withCredentials
            }
        );
    }


    uploadHardwareProductionUnit(
        data: FormData
    ): Observable<UploadResponseProductionUnitUploadDtoProductionUnitUploadDto> {
        return this.httpClient.post<UploadResponseProductionUnitUploadDtoProductionUnitUploadDto>(
            `${this.configuration.basePath}/software-production-unit/hardware-pu-upload`,
            data,
            {
                withCredentials: this.configuration.withCredentials
            }
        );
    }

    camelCaseToFormattedString(input: string, toTitleCase: boolean): string {
        const formatted = input.replace(/([A-Z])/g, ' $1'); // Add space before uppercase letters
        return toTitleCase
            ? formatted.replace(/^./, str => str.toUpperCase()) // Capitalize the first letter for Title Case
            : formatted.toLowerCase(); // Convert the entire string to lowercase
    }

    removeSpace(input: string): string {
        return input.replace(' ', '');
    }

    toArray(input: string): string[] {
        // Check if the input contains a comma
        if (input.includes(',')) {
            return input.split(',').map(item => item.trim());
        }
        // If no comma, return the input as a single-element array
        return [input.trim()];
    }


}
