/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkProductSectionDefinitionAndDataPojo } from './work-product-section-definition-and-data-pojo';
import { WorkProductDocumentStyleDto } from './work-product-document-style-dto';
import { NameIdPojo } from './name-id-pojo';
import { AttachmentPojo } from './attachment-pojo';


export interface WorkProductDefinitionPojo { 
    id?: number;
    previousVersionId?: number;
    nextVersionId?: number;
    completed?: boolean;
    createdAt?: string;
    portalAccountId?: number;
    createdBy?: NameIdPojo;
    createdByEmail?: string;
    createdByDesignation?: string;
    reviewStatus?: WorkProductDefinitionPojo.ReviewStatusEnum;
    approverRoles?: Array<NameIdPojo>;
    standards?: Array<NameIdPojo>;
    name?: string;
    footerInfo?: string;
    identifier?: string;
    versionNumber?: number;
    logo?: AttachmentPojo;
    versionNumberDisplayName?: string;
    documentStyle?: WorkProductDocumentStyleDto;
    purposeAnnotations?: Array<WorkProductDefinitionPojo.PurposeAnnotationsEnum>;
    sections?: Array<WorkProductSectionDefinitionAndDataPojo>;
}
export namespace WorkProductDefinitionPojo {
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        Approved: 'APPROVED' as ReviewStatusEnum,
        Pending: 'PENDING' as ReviewStatusEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusEnum
    };
    export type PurposeAnnotationsEnum = 'USER_ACCEPTANCE' | 'QUALITY_ASSURANCE' | 'DOCUMENTATION';
    export const PurposeAnnotationsEnum = {
        UserAcceptance: 'USER_ACCEPTANCE' as PurposeAnnotationsEnum,
        QualityAssurance: 'QUALITY_ASSURANCE' as PurposeAnnotationsEnum,
        Documentation: 'DOCUMENTATION' as PurposeAnnotationsEnum
    };
}


