/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { QueryResultsWorkProductPojo } from '../model/models';
import { WorkProductDto } from '../model/models';
import { WorkProductPojo } from '../model/models';
import { WorkProductSectionDetailDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateNewWorkProductVersionRequestParams {
    id: number;
}

export interface CreateWorkProductRequestParams {
    workProductDto: WorkProductDto;
}

export interface DeleteWorkProductRequestParams {
    id: number;
}

export interface GetWorkProductByIdRequestParams {
    id: number;
}

export interface SaveWorkProductSectionDetailsRequestParams {
    id: number;
    requestApproval: boolean;
    selectedApprover: number;
    workProductSectionDetailDto: Array<WorkProductSectionDetailDto>;
}

export interface SearchWorkProductRequestParams {
    identifier?: string;
    reviewedAt?: string;
    completed?: boolean;
    reviewer?: string;
    title?: string;
    versionNumber?: number;
    reasonForReview?: string;
    processExecutionInstance?: string;
    portalAccount?: string;
    workProductDefinition?: string;
    createdAt?: string;
    versionNumberDisplayName?: string;
    createdBy?: string;
    previousVersion?: string;
    scope?: 'ORGANISATION' | 'PROCESS';
    reviewStatus?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    id?: number;
    department?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    purpose?: 'USER_ACCEPTANCE' | 'QUALITY_ASSURANCE' | 'DOCUMENTATION';
    departmentId?: number;
    createdAfter?: string;
    priorityItems?: Array<object>;
    processId?: number;
    limit?: number;
    createdBefore?: string;
    keyword?: string;
    orderColumn?: string;
    playerId?: number;
    order?: 'ASC' | 'DESC';
}

export interface UpdateWorkProductRequestParams {
    id: number;
    workProductDto: WorkProductDto;
}


@Injectable({
  providedIn: 'root'
})
export class WorkProductControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Work Product
     * Create New Version of Work Product
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNewWorkProductVersion(requestParameters: CreateNewWorkProductVersionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WorkProductPojo>;
    public createNewWorkProductVersion(requestParameters: CreateNewWorkProductVersionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WorkProductPojo>>;
    public createNewWorkProductVersion(requestParameters: CreateNewWorkProductVersionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WorkProductPojo>>;
    public createNewWorkProductVersion(requestParameters: CreateNewWorkProductVersionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling createNewWorkProductVersion.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<WorkProductPojo>(`${this.configuration.basePath}/work-products/${encodeURIComponent(String(id))}/new-version`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product
     * Create Work Product
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createWorkProduct(requestParameters: CreateWorkProductRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WorkProductPojo>;
    public createWorkProduct(requestParameters: CreateWorkProductRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WorkProductPojo>>;
    public createWorkProduct(requestParameters: CreateWorkProductRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WorkProductPojo>>;
    public createWorkProduct(requestParameters: CreateWorkProductRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const workProductDto = requestParameters.workProductDto;
        if (workProductDto === null || workProductDto === undefined) {
            throw new Error('Required parameter workProductDto was null or undefined when calling createWorkProduct.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<WorkProductPojo>(`${this.configuration.basePath}/work-products`,
            workProductDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product
     * Delete Work Product
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWorkProduct(requestParameters: DeleteWorkProductRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public deleteWorkProduct(requestParameters: DeleteWorkProductRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public deleteWorkProduct(requestParameters: DeleteWorkProductRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public deleteWorkProduct(requestParameters: DeleteWorkProductRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteWorkProduct.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/work-products/${encodeURIComponent(String(id))}/delete`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product
     * Get All Work Product
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllWorkProduct(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<WorkProductPojo>>;
    public getAllWorkProduct(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<WorkProductPojo>>>;
    public getAllWorkProduct(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<WorkProductPojo>>>;
    public getAllWorkProduct(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<WorkProductPojo>>(`${this.configuration.basePath}/work-products/get-all-work-product`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product
     * Get Work Product by Id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkProductById(requestParameters: GetWorkProductByIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WorkProductPojo>;
    public getWorkProductById(requestParameters: GetWorkProductByIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WorkProductPojo>>;
    public getWorkProductById(requestParameters: GetWorkProductByIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WorkProductPojo>>;
    public getWorkProductById(requestParameters: GetWorkProductByIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getWorkProductById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<WorkProductPojo>(`${this.configuration.basePath}/work-products/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product
     * Save Work Product Section Details
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveWorkProductSectionDetails(requestParameters: SaveWorkProductSectionDetailsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public saveWorkProductSectionDetails(requestParameters: SaveWorkProductSectionDetailsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public saveWorkProductSectionDetails(requestParameters: SaveWorkProductSectionDetailsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public saveWorkProductSectionDetails(requestParameters: SaveWorkProductSectionDetailsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling saveWorkProductSectionDetails.');
        }
        const requestApproval = requestParameters.requestApproval;
        if (requestApproval === null || requestApproval === undefined) {
            throw new Error('Required parameter requestApproval was null or undefined when calling saveWorkProductSectionDetails.');
        }
        const selectedApprover = requestParameters.selectedApprover;
        if (selectedApprover === null || selectedApprover === undefined) {
            throw new Error('Required parameter selectedApprover was null or undefined when calling saveWorkProductSectionDetails.');
        }
        const workProductSectionDetailDto = requestParameters.workProductSectionDetailDto;
        if (workProductSectionDetailDto === null || workProductSectionDetailDto === undefined) {
            throw new Error('Required parameter workProductSectionDetailDto was null or undefined when calling saveWorkProductSectionDetails.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (requestApproval !== undefined && requestApproval !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requestApproval, 'requestApproval');
        }
        if (selectedApprover !== undefined && selectedApprover !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>selectedApprover, 'selectedApprover');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/work-products/${encodeURIComponent(String(id))}/save-section-details`,
            workProductSectionDetailDto,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product
     * Filter Work Product
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchWorkProduct(requestParameters: SearchWorkProductRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsWorkProductPojo>;
    public searchWorkProduct(requestParameters: SearchWorkProductRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsWorkProductPojo>>;
    public searchWorkProduct(requestParameters: SearchWorkProductRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsWorkProductPojo>>;
    public searchWorkProduct(requestParameters: SearchWorkProductRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const identifier = requestParameters.identifier;
        const reviewedAt = requestParameters.reviewedAt;
        const completed = requestParameters.completed;
        const reviewer = requestParameters.reviewer;
        const title = requestParameters.title;
        const versionNumber = requestParameters.versionNumber;
        const reasonForReview = requestParameters.reasonForReview;
        const processExecutionInstance = requestParameters.processExecutionInstance;
        const portalAccount = requestParameters.portalAccount;
        const workProductDefinition = requestParameters.workProductDefinition;
        const createdAt = requestParameters.createdAt;
        const versionNumberDisplayName = requestParameters.versionNumberDisplayName;
        const createdBy = requestParameters.createdBy;
        const previousVersion = requestParameters.previousVersion;
        const scope = requestParameters.scope;
        const reviewStatus = requestParameters.reviewStatus;
        const id = requestParameters.id;
        const department = requestParameters.department;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const purpose = requestParameters.purpose;
        const departmentId = requestParameters.departmentId;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const processId = requestParameters.processId;
        const limit = requestParameters.limit;
        const createdBefore = requestParameters.createdBefore;
        const keyword = requestParameters.keyword;
        const orderColumn = requestParameters.orderColumn;
        const playerId = requestParameters.playerId;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (reviewedAt !== undefined && reviewedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewedAt, 'reviewedAt');
        }
        if (completed !== undefined && completed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completed, 'completed');
        }
        if (reviewer !== undefined && reviewer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewer, 'reviewer');
        }
        if (title !== undefined && title !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>title, 'title');
        }
        if (versionNumber !== undefined && versionNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>versionNumber, 'versionNumber');
        }
        if (reasonForReview !== undefined && reasonForReview !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reasonForReview, 'reasonForReview');
        }
        if (processExecutionInstance !== undefined && processExecutionInstance !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>processExecutionInstance, 'processExecutionInstance');
        }
        if (portalAccount !== undefined && portalAccount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccount, 'portalAccount');
        }
        if (workProductDefinition !== undefined && workProductDefinition !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>workProductDefinition, 'workProductDefinition');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (versionNumberDisplayName !== undefined && versionNumberDisplayName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>versionNumberDisplayName, 'versionNumberDisplayName');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (previousVersion !== undefined && previousVersion !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>previousVersion, 'previousVersion');
        }
        if (scope !== undefined && scope !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>scope, 'scope');
        }
        if (reviewStatus !== undefined && reviewStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewStatus, 'reviewStatus');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (department !== undefined && department !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>department, 'department');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (purpose !== undefined && purpose !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>purpose, 'purpose');
        }
        if (departmentId !== undefined && departmentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>departmentId, 'departmentId');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (processId !== undefined && processId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>processId, 'processId');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (playerId !== undefined && playerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerId, 'playerId');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsWorkProductPojo>(`${this.configuration.basePath}/work-products`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product
     * Update Work Product
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkProduct(requestParameters: UpdateWorkProductRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WorkProductPojo>;
    public updateWorkProduct(requestParameters: UpdateWorkProductRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WorkProductPojo>>;
    public updateWorkProduct(requestParameters: UpdateWorkProductRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WorkProductPojo>>;
    public updateWorkProduct(requestParameters: UpdateWorkProductRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateWorkProduct.');
        }
        const workProductDto = requestParameters.workProductDto;
        if (workProductDto === null || workProductDto === undefined) {
            throw new Error('Required parameter workProductDto was null or undefined when calling updateWorkProduct.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<WorkProductPojo>(`${this.configuration.basePath}/work-products/${encodeURIComponent(String(id))}/edit`,
            workProductDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
