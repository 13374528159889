<div class="m-0 rounded rounded-3">
    <br>
    <div class="d-flex justify-content-between p-3 align-items-center">
        <div class="d-flex justify-content-start align-items-center">
            <h6 class="modal-title mb-0 fs-5" id="modal-title">Plan a Sprint</h6>
        </div>
        <button (click)="close()" class="btn-close" type="button" aria-label="Close"></button>
    </div>
    <div class="modal-body">

        <div *ngIf="showAlertMessageTrigger"
             [ngClass]="{ anim: showAlertMessageTrigger }"
             [class.alert-success]="getMessageType() === AlertType.success"
             [class.alert-danger]="getMessageType() === AlertType.error"
             [class.alert-primary]="getMessageType() === AlertType.primary"
             [class.alert-info]="getMessageType() === AlertType.info"
             class="alert mb-4 fs-14 text-center"
        >
            {{ getAlertMessage() }}
        </div>

        <div class="mb-4">
            <app-module-release-planning
                    (saveEvent)="handleSaveEvent($event)"
                    [goBackAfterSave]="false"
                    [saveButtonText]="'Done Planning'"/>
        </div>

    </div>
</div>
