/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MRPCalendarUatUseCasePojo } from './mrp-calendar-uat-use-case-pojo';


export interface MRPCalendarDayPojo { 
    weekday?: string;
    date?: number;
    dateWithSuffix?: string;
    localDate?: string;
    spuForRelease?: Array<string>;
    shouldShowScores?: boolean;
    uatAndUseCases?: Array<MRPCalendarUatUseCasePojo>;
    actualScore?: number;
    forecastScore?: string;
    uatDay?: boolean;
}

