/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';
import { WorkItemDefinitionAndDataPojo } from './work-item-definition-and-data-pojo';
import { NameIdParentPojo } from './name-id-parent-pojo';
import { ReviewStatusPojo } from './review-status-pojo';


export interface WorkProductSectionDefinitionAndDataPojo { 
    id?: number;
    createdAt?: string;
    createdBy?: NameIdPojo;
    sectionLabel?: string;
    required?: boolean;
    workProductDefinitionId?: number;
    ordinal?: number;
    approverRoles?: Array<NameIdPojo>;
    playerRoles?: Array<NameIdPojo>;
    dependentSectionDefinitions?: Array<NameIdParentPojo>;
    dependencySectionDefinitions?: Array<NameIdParentPojo>;
    workItemDefinition?: WorkItemDefinitionAndDataPojo;
    sectionDataId?: number;
    sectionDataCreatedAt?: string;
    sectionDataCreatedBy?: NameIdPojo;
    workProductId?: number;
    dependencySectionDataList?: Array<NameIdPojo>;
    sectionDataReviewStatus?: ReviewStatusPojo;
    selectedSectionApprover?: NameIdPojo;
    sectionApprovers?: Array<NameIdPojo>;
}

