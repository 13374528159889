
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="modal-title">Select an Icon to Represent Your Process</h3>
            <button (click)="modalRef.hide()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="d-flex flex-wrap justify-content-center">
                <div
                        *ngFor="let icon of icons"
                        (click)="selectIcon(icon)"
                        [ngClass]="{'active': selectedIcon === icon}" class="icon-item m-2 text-center card" style="cursor: pointer; width: 60px; height: 60px;">
                    <div class="card-body">
                        <i class="iconify " [attr.data-icon]="icon" style="font-size: 30px;"></i>
                    </div>

                </div>







            </div>
        </div>
    </div>
