/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ADTSchemaFieldDataDto } from './adt-schema-field-data-dto';
import { AttachmentDto } from './attachment-dto';
import { CalendarDataDto } from './calendar-data-dto';


export interface WorkItemADTDataDto { 
    id?: number;
    adtSchemaType: WorkItemADTDataDto.AdtSchemaTypeEnum;
    workItemDefinitionADTId: number;
    labelData: string;
    file?: AttachmentDto;
    workItemId?: number;
    calendarDataList?: Array<CalendarDataDto>;
    adtSchemaFieldDataList?: Array<ADTSchemaFieldDataDto>;
}
export namespace WorkItemADTDataDto {
    export type AdtSchemaTypeEnum = 'CHECKLIST' | 'DATE' | 'DATE_TIME' | 'TIME' | 'TEXT_AREA' | 'TEXT_FIELD' | 'URL' | 'CHECKBOX' | 'RADIO' | 'SELECT' | 'MULTI_SELECT' | 'NUMBER' | 'EMAIL_ADDRESS' | 'FILE' | 'TABLE' | 'CALENDAR';
    export const AdtSchemaTypeEnum = {
        Checklist: 'CHECKLIST' as AdtSchemaTypeEnum,
        Date: 'DATE' as AdtSchemaTypeEnum,
        DateTime: 'DATE_TIME' as AdtSchemaTypeEnum,
        Time: 'TIME' as AdtSchemaTypeEnum,
        TextArea: 'TEXT_AREA' as AdtSchemaTypeEnum,
        TextField: 'TEXT_FIELD' as AdtSchemaTypeEnum,
        Url: 'URL' as AdtSchemaTypeEnum,
        Checkbox: 'CHECKBOX' as AdtSchemaTypeEnum,
        Radio: 'RADIO' as AdtSchemaTypeEnum,
        Select: 'SELECT' as AdtSchemaTypeEnum,
        MultiSelect: 'MULTI_SELECT' as AdtSchemaTypeEnum,
        Number: 'NUMBER' as AdtSchemaTypeEnum,
        EmailAddress: 'EMAIL_ADDRESS' as AdtSchemaTypeEnum,
        File: 'FILE' as AdtSchemaTypeEnum,
        Table: 'TABLE' as AdtSchemaTypeEnum,
        Calendar: 'CALENDAR' as AdtSchemaTypeEnum
    };
}


