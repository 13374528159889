export const environment = {
    production: true,
    preProduction: false,
    apiBaseUrl: '/api',
    siteUrl: 'https://support.byteworks.com.ng/api',
    brandName: 'Customer Fulfillment Service',
    keycloakConfig: {
        clientId: 'cfs-frontend',
        realm: 'cfs',
        url: 'https://support.byteworks.com.ng/auth'
    },
    sessionTimeout: 1440,
    defaultCountry: 'NGA',
    recaptcha: {
        siteKey: '6LcaD7soAAAAACRHrPdsCM8BtR3Ax7GW5v7_sWNt'
    },
    dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
    timeFormat: 'HH:mm:ss',
    dateFormat: 'YYYY-MM-DD',
    qaProcessCode: 'TESTING',
    ciProcessCode: 'CI',
    designProcessCode: 'DESIGN',
    releaseProcessCode: 'RELEASE',
    requirementDocumentationProcessCode: 'RD',
    requirementDocumentationApprovalProcessCode: 'RA',
    UATProcessCode: 'UAT',
    monitoringProcessCode: 'MONITORING',
    requirementApprovalStage: 'RA',
    bwAccountCode: "CFSACC000002",
    fourDXProcessCode: "4DX",
    productionStandardProcessCode: 'PSTD',
    mrpApprovalProcessCode: 'MRPA',
    designApprovalProcessCode: 'DESIGNA',
    mrpProcessCode: 'MRP',
    productionProcessProcessCode: 'PP',
};
