<div class="row g-3 mb-3">

    <div class="col-md-12">
        <div class="form">
            <label *ngIf="title" class="form-label" [class.required]="required">{{title | titlecase}}</label> <br *ngIf="title"/>
            <div class="p-2 mb-md-3 rounded-2 col-12 d-flex justify-content-center"  *ngIf="previousLogoId && !isFileUploaded" style="background: #f5f8fa;">
                <img class="img-fluid" style="height: 200px; border-radius: 7px; object-fit: scale-down" [src]=imageUrl(this.previousLogoId)>
            </div>
            <div class="upload-box mt-2">
                <ngx-dropzone
                        [disabled]="viewOnly"
                        [multiple]="true"
                        (change)="onSelect($event)"
                        class="upload"
                        [accept]="accepts"
                >
                    <ngx-dropzone-label>
                        <div
                                class="d-flex align-items-center flex-wrap flex-sm-nowrap gap-2 gap-sm-2 gap-md-5"
                        >
                            <label class="file-select-card pointer">
                                <img src="assets/img/upload.svg" alt="">
                                <div class="fw-medium">Drag and drop {{ title }} here or <span
                                        style="color: #121C52;"
                                        class="fw-semibold"><u>Choose file</u></span>
                                </div>
                            </label>
                        </div>
                    </ngx-dropzone-label>
                </ngx-dropzone>
            </div>
            <div class="d-flex justify-content-between align-items-center my-2">
                <div>Supported Format: <span class="fw-medium">{{ acceptsText }}.</span></div>
                <div>Maximum Size: <span class="fw-medium">{{ getFileSize(maxDocSize) }}</span></div>
            </div>
        </div>
    </div>

</div>

<div class="mb-4" *ngIf="showPreview">
    <ng-container *ngFor="let f of files; let i = index">
        <div class="p-2 mb-md-3 rounded-2" style="background: #f5f8fa;">
            <div class="d-md-flex justify-content-between align-items-center my-1 overflow-hidden">
                <div class="d-flex align-items-center">
                    <div><span class="iconify fs-20 text-danger" data-icon="icomoon-free:file-pdf"></span></div>
                    <div class="ms-2">{{ f.fileName }}</div>
                </div>
                <div class="d-flex align-items-center">
                    <button class="btn btn-light link-color p-2 rounded-2 ms-2"
                            style="box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.20); background-color: #fff;"
                            (click)="preview(f)">
                        Preview
                    </button>
                    <button *ngIf="!viewOnly" class="btn btn-light text-danger p-2 rounded-2 ms-2"
                            style="box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.20); background-color: #fff;"
                            (click)="delete(f)">
                        Remove
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
