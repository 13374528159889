/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkProductDocumentStyleDto } from './work-product-document-style-dto';
import { WorkItemDefinitionADTDto } from './work-item-definition-adt-dto';


export interface WorkProductSectionDefinitionDetailDto { 
    workProductSectionDefinitionId: number;
    workItemDefinitionId: number;
    workItemDefinitionType: WorkProductSectionDefinitionDetailDto.WorkItemDefinitionTypeEnum;
    documentStyle?: WorkProductDocumentStyleDto;
    workItemDefinitionADTDtos?: Array<WorkItemDefinitionADTDto>;
}
export namespace WorkProductSectionDefinitionDetailDto {
    export type WorkItemDefinitionTypeEnum = 'ADT' | 'PRODUCTION_UNIT' | 'SERVICE_UNIT' | 'IMAGE';
    export const WorkItemDefinitionTypeEnum = {
        Adt: 'ADT' as WorkItemDefinitionTypeEnum,
        ProductionUnit: 'PRODUCTION_UNIT' as WorkItemDefinitionTypeEnum,
        ServiceUnit: 'SERVICE_UNIT' as WorkItemDefinitionTypeEnum,
        Image: 'IMAGE' as WorkItemDefinitionTypeEnum
    };
}


