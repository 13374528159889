/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WorkProductDto { 
    id?: number;
    title: string;
    workProductDefinitionId: number;
    versionNumberDisplayName?: string;
    processExecutionInstanceId?: number;
    departmentId: number;
    scope: WorkProductDto.ScopeEnum;
}
export namespace WorkProductDto {
    export type ScopeEnum = 'ORGANISATION' | 'PROCESS';
    export const ScopeEnum = {
        Organisation: 'ORGANISATION' as ScopeEnum,
        Process: 'PROCESS' as ScopeEnum
    };
}


