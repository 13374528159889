/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkItemStatusGroupPojo } from './work-item-status-group-pojo';
import { NameIdPojo } from './name-id-pojo';
import { WorkItemPojo } from './work-item-pojo';
import { WorkItemDefinitionADTPojo } from './work-item-definition-adt-pojo';


export interface WorkItemDefinitionAndDataPojo { 
    id?: number;
    createdAt?: string;
    createdBy?: NameIdPojo;
    itemType?: WorkItemDefinitionAndDataPojo.ItemTypeEnum;
    required?: boolean;
    ordered?: boolean;
    minimumNumberOfItems?: number;
    maximumNumberOfItems?: number;
    identifier?: string;
    label?: string;
    workItemStatusGroup?: WorkItemStatusGroupPojo;
    workProductSectionDefinition?: NameIdPojo;
    workItemDefinitionADTs?: Array<WorkItemDefinitionADTPojo>;
    workItemDataList?: Array<WorkItemPojo>;
}
export namespace WorkItemDefinitionAndDataPojo {
    export type ItemTypeEnum = 'ADT' | 'PRODUCTION_UNIT' | 'SERVICE_UNIT' | 'IMAGE';
    export const ItemTypeEnum = {
        Adt: 'ADT' as ItemTypeEnum,
        ProductionUnit: 'PRODUCTION_UNIT' as ItemTypeEnum,
        ServiceUnit: 'SERVICE_UNIT' as ItemTypeEnum,
        Image: 'IMAGE' as ItemTypeEnum
    };
}


