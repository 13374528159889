/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PerformanceMilestoneScoreboardPojo } from './performance-milestone-scoreboard-pojo';


export interface PerformanceScoreboardPojo { 
    lagDueAt?: string;
    lagActivityCompletionRate?: number;
    numberOfCompletedActivitiesInLag?: number;
    numberOfPendingActivitiesInLag?: number;
    totalNumberOfActivitiesInLag?: number;
    milestoneScoreboardPojos?: Array<PerformanceMilestoneScoreboardPojo>;
}

