import { Component, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-flow-chart',
  templateUrl: './flow-chart.component.html',
  styleUrls: ['./flow-chart.component.css']
})
export class FlowChartComponent implements AfterViewInit, OnChanges {





  flowChartData = {
    nodes: [
      { id: 'node1', label: 'Approved Requirement', type: 'input' },
      { id: 'node2', label: 'Design on Figma', type: 'process' },
      { id: 'node3', label: 'Sketch Design', type: 'process' },
      { id: 'node4', label: 'Design Approval', type: 'decision' },
      { id: 'node5', label: 'Design Artifact', type: 'process' },
      { id: 'node11', label: 'UI/UX Testing', type: 'process' },
      { id: 'node12', label: 'User Feedback', type: 'process' },
      { id: 'node13', label: 'Bug Fixes', type: 'process' },
      { id: 'node14', label: 'Quality Assurance', type: 'decision' },
    ]
  };

  customNodeStyles = {
    process: { color: '#3498db', shape: 'rectangle' },
    input: { color: '#FF9800', shape: 'parallelogram', skew: 30 },
    output: { color: '#4CAF50', shape: 'parallelogram', skew: -30 },
    decision: { color: '#F39C12', shape: 'kite' }
  };

  @Input() data: any = { nodes: [], connections: [] };
  @Input() width = 1500;
  @Input() nodeStyles: any = {};
  private svg: any;
  private height: number ;

  ngAfterViewInit() {
    this.createFlowChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.createFlowChart();
    }
  }

  private createFlowChart() {
    d3.select("#flowchart-container").select("svg").remove();

    this.svg = d3.select("#flowchart-container")
        .append("svg")
        .attr("width", this.width)
        .attr("height", this.height);

    this.generateConnections();

    const validConnections = this.filterSelfLoops(this.data.connections);

    this.arrangeNodes();

    this.renderLinks(validConnections);

    this.renderNodes();
  }

  private generateConnections() : any{
    const nodes = this.data.nodes;
    const connections = [];

    for (let i = 0; i < nodes.length - 1; i++) {
      connections.push({
        source: nodes[i].id,
        target: nodes[i + 1].id
      });
    }

    this.data.connections = connections;
  }

  private filterSelfLoops(connections: any[]) {
    return connections.filter(d => d.source !== d.target);
  }

  private arrangeNodes() {
    const nodes = this.data.nodes;
    const columnsPerRow = 5;
    const rowSpacing = 150;
    let currentX = 100;
    let currentY = 150;
    let currentRow = 0;

    const totalRows = Math.ceil(nodes.length / columnsPerRow);

    this.height = 150 + totalRows * rowSpacing;

    nodes.forEach((node, index) => {
      node.x = currentX;
      node.y = currentY;

      if ((index + 1) % columnsPerRow === 0) {
        currentRow++;

        currentY = 150 + currentRow * rowSpacing;

        if (currentRow % 2 === 1) {
          currentX = 100 + (columnsPerRow - 1) * 200;
        } else {
          currentX = 100;
        }
      } else {
        if (currentRow % 2 === 0) {
          currentX += 200;
        } else {
          currentX -= 200;
        }
      }
    });
  }

  private renderLinks(connections: any[]) {
    this.svg.selectAll(".link")
        .data(connections)
        .enter()
        .append("line")
        .attr("class", "link")
        .attr("x1", d => this.getNodeEdge(d.source).x)
        .attr("y1", d => this.getNodeEdge(d.source).y)
        .attr("x2", d => this.getNodeEdge(d.target).x)
        .attr("y2", d => this.getNodeEdge(d.target).y)
        .style("stroke", "#ccc")
        .style("stroke-width", 2)
        .attr("marker-end", "url(#arrow)")

    // Append marker definition for arrowheads
    this.svg.append("defs").append("marker")
        .attr("id", "arrow")
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 57)
        .attr("refY", 0)
        .attr("orient", "auto")
        .attr("markerWidth", 8)
        .attr("markerHeight", 8)
        .append("path")
        .attr("d", "M0,-5L10,0L0,5")
        .style("fill", "#ccc");
  }


  private renderNodes() {
    const nodeElements = this.svg.selectAll(".node")
        .data(this.data.nodes)
        .enter().append("g")
        .attr("class", "node")
        .attr("transform", d => `translate(${d.x}, ${d.y})`);

    nodeElements.each((d: any, i: number, nodes: any[]) => {
      const node = d3.select(nodes[i]);
      const style = this.nodeStyles[d.type];

      const width = 150;
      const height = 70;

      if (style.shape === 'rectangle') {
        node.append("rect")
            .attr("width", width)
            .attr("height", height)
            .attr("rx", 5)
            .attr("ry", 5)
            .style("fill", style.color);
      } else if (style.shape === 'parallelogram') {
        node.append("polygon")
            .attr("points", this.getParallelogramPoints(d, width, height, style.skew))
            .style("fill", style.color);
      } else if (style.shape === 'kite') {
        node.append("polygon")
            .attr("points", this.getKitePoints(d, width, height))
            .style("fill", style.color);
      }

      node.append("foreignObject")
          .attr("x", 20)
          .attr("y", 10)
          .attr("width", width - 50)
          .attr("height", height - 20)
          .append("xhtml:div")
          .style("width", "100%")
          .style("height", "100%")
          .style("overflow", "hidden")
          .style("display", "flex")
          .style("align-items", "center")
          .style("justify-content", "center")
          .style("text-align", "center")
          .style("color", "white")
          .style("font-size", "12px")
          .style("word-wrap", "break-word")
          .style("white-space", "normal")
          .text(d.label);
    });
  }

  private getNodeEdge(id: string) {
    const node = this.data.nodes.find(n => n.id === id);
    const width = 150; // Node width
    const height = 70; // Node height

    if (!node) return { x: 0, y: 0 }; // Fallback for invalid nodes

    // Return the center of the node
    return {
      x: node.x + width / 2,
      y: node.y + height / 2
    };
  }

  private getKitePoints(d: any, width: number, height: number) {
    return [
      width / 2, 0,
      width, height / 2,
      width / 2, height,
      0, height / 2
    ].join(" ");
  }

  private getParallelogramPoints(d: any, width: number, height: number, skew: number) {
    return [
      0, 0,
      width - skew, 0,
      width, height,
      skew, height
    ].join(" ");
  }
}
