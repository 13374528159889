import {Component, EventEmitter} from '@angular/core';
import {AlertType} from "../../../../extranet/report-issue/report-issue.component";
import {NameIdPojo} from "../../../../../../../sdk/customer-fulfillment-api-sdk";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-mrp-dialog',
  templateUrl: './mrp-dialog.component.html',
  styleUrls: ['./mrp-dialog.component.css']
})
export class MrpDialogComponent {

  sprintPlanEvent: EventEmitter<NameIdPojo> = new EventEmitter()

  constructor(private modalRef: BsModalRef) {
  }


  /////////////////////
  //////ALERT
  /////////////////////
  private alertMessage: { msg: string; type: AlertType } = {
    msg: '',
    type: AlertType.primary
  }
  showAlertMessageTrigger = false;

  showAlertMessage(msg: any, type: AlertType): void {
    this.alertMessage.msg = msg;
    this.alertMessage.type = type;
    this.showAlertMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showAlertMessageTrigger = false;
    }, 10000);
  }

  getAlertMessage(): string {
    return this.alertMessage.msg;
  }

  getMessageType(): AlertType {
    return this.alertMessage.type;
  }

  protected readonly AlertType = AlertType;

  /////////////////////
  //////END ALERT
  /////////////////////

  handleSaveEvent($event: NameIdPojo) {
    this.sprintPlanEvent.emit($event);
    this.close();
  }

  close() {
    this.modalRef.hide();
  }
}
