/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ADTSchemaFieldDto } from './adt-schema-field-dto';


export interface WorkItemDefinitionADTDto { 
    id?: number;
    adtSchemaType: WorkItemDefinitionADTDto.AdtSchemaTypeEnum;
    displayPositionXY: string;
    label: string;
    hasFields: boolean;
    required: boolean;
    calendarPurpose?: WorkItemDefinitionADTDto.CalendarPurposeEnum;
    maxSizeInMb?: number;
    adtSchemaFields?: Array<ADTSchemaFieldDto>;
}
export namespace WorkItemDefinitionADTDto {
    export type AdtSchemaTypeEnum = 'CHECKLIST' | 'DATE' | 'DATE_TIME' | 'TIME' | 'TEXT_AREA' | 'TEXT_FIELD' | 'URL' | 'CHECKBOX' | 'RADIO' | 'SELECT' | 'MULTI_SELECT' | 'NUMBER' | 'EMAIL_ADDRESS' | 'FILE' | 'TABLE' | 'CALENDAR';
    export const AdtSchemaTypeEnum = {
        Checklist: 'CHECKLIST' as AdtSchemaTypeEnum,
        Date: 'DATE' as AdtSchemaTypeEnum,
        DateTime: 'DATE_TIME' as AdtSchemaTypeEnum,
        Time: 'TIME' as AdtSchemaTypeEnum,
        TextArea: 'TEXT_AREA' as AdtSchemaTypeEnum,
        TextField: 'TEXT_FIELD' as AdtSchemaTypeEnum,
        Url: 'URL' as AdtSchemaTypeEnum,
        Checkbox: 'CHECKBOX' as AdtSchemaTypeEnum,
        Radio: 'RADIO' as AdtSchemaTypeEnum,
        Select: 'SELECT' as AdtSchemaTypeEnum,
        MultiSelect: 'MULTI_SELECT' as AdtSchemaTypeEnum,
        Number: 'NUMBER' as AdtSchemaTypeEnum,
        EmailAddress: 'EMAIL_ADDRESS' as AdtSchemaTypeEnum,
        File: 'FILE' as AdtSchemaTypeEnum,
        Table: 'TABLE' as AdtSchemaTypeEnum,
        Calendar: 'CALENDAR' as AdtSchemaTypeEnum
    };
    export type CalendarPurposeEnum = 'EVENT' | 'SPRINT' | 'PROJECT';
    export const CalendarPurposeEnum = {
        Event: 'EVENT' as CalendarPurposeEnum,
        Sprint: 'SPRINT' as CalendarPurposeEnum,
        Project: 'PROJECT' as CalendarPurposeEnum
    };
}


