/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ADTSchemaFieldDto { 
    id?: number;
    fieldName: string;
    fieldType: ADTSchemaFieldDto.FieldTypeEnum;
    minLength?: number;
    maxLength?: number;
    required?: boolean;
    ordinal: number;
    unique?: boolean;
}
export namespace ADTSchemaFieldDto {
    export type FieldTypeEnum = 'BOOLEAN' | 'STRING' | 'DATE' | 'DATE_TIME' | 'TIME' | 'DOUBLE';
    export const FieldTypeEnum = {
        Boolean: 'BOOLEAN' as FieldTypeEnum,
        String: 'STRING' as FieldTypeEnum,
        Date: 'DATE' as FieldTypeEnum,
        DateTime: 'DATE_TIME' as FieldTypeEnum,
        Time: 'TIME' as FieldTypeEnum,
        Double: 'DOUBLE' as FieldTypeEnum
    };
}


