import {Injectable} from '@angular/core';
import {PermissionType} from "../account/permission-type.enum";
import {AuthenticationService} from "../../services/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class AppPermissionsService {

    constructor(private authenticationService: AuthenticationService) {
    }

    createWorkProductDefinitionPermissions(): Array<PermissionType> {
        return [
            PermissionType.CREATE_WORK_PRODUCT_DEFINITION,
            PermissionType.DELETE_WORK_PRODUCT_DEFINITION,
            PermissionType.UPDATE_WORK_PRODUCT_DEFINITION,
        ]
    }

    createWorkProduct(): Array<PermissionType> {
        return [
            PermissionType.CREATE_WORK_PRODUCT,
            PermissionType.UPDATE_WORK_PRODUCT,
            PermissionType.VIEW_WORK_PRODUCT
        ]
    }

    canCreateWorkProductDefinition(): boolean {
        return this.authenticationService.hasAnyPermission(
            this.createWorkProductDefinitionPermissions()
        )
    }

    canCreateWorkProduct(): boolean {
        return this.authenticationService.hasAnyPermission(
            this.createWorkProduct()
        )
    }


}
