/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalendarDataPojo } from './calendar-data-pojo';
import { AttachmentPojo } from './attachment-pojo';
import { ADTSchemaFieldDataPojo } from './adt-schema-field-data-pojo';


export interface WorkItemADTDataPojo { 
    id?: number;
    workItemDefinitionADTId?: number;
    workItemDefinitionADTSchemaType?: WorkItemADTDataPojo.WorkItemDefinitionADTSchemaTypeEnum;
    workItemId?: number;
    labelData?: string;
    file?: AttachmentPojo;
    adtSchemaFieldDataList?: Array<ADTSchemaFieldDataPojo>;
    calendarDataList?: Array<CalendarDataPojo>;
}
export namespace WorkItemADTDataPojo {
    export type WorkItemDefinitionADTSchemaTypeEnum = 'CHECKLIST' | 'DATE' | 'DATE_TIME' | 'TIME' | 'TEXT_AREA' | 'TEXT_FIELD' | 'URL' | 'CHECKBOX' | 'RADIO' | 'SELECT' | 'MULTI_SELECT' | 'NUMBER' | 'EMAIL_ADDRESS' | 'FILE' | 'TABLE' | 'CALENDAR';
    export const WorkItemDefinitionADTSchemaTypeEnum = {
        Checklist: 'CHECKLIST' as WorkItemDefinitionADTSchemaTypeEnum,
        Date: 'DATE' as WorkItemDefinitionADTSchemaTypeEnum,
        DateTime: 'DATE_TIME' as WorkItemDefinitionADTSchemaTypeEnum,
        Time: 'TIME' as WorkItemDefinitionADTSchemaTypeEnum,
        TextArea: 'TEXT_AREA' as WorkItemDefinitionADTSchemaTypeEnum,
        TextField: 'TEXT_FIELD' as WorkItemDefinitionADTSchemaTypeEnum,
        Url: 'URL' as WorkItemDefinitionADTSchemaTypeEnum,
        Checkbox: 'CHECKBOX' as WorkItemDefinitionADTSchemaTypeEnum,
        Radio: 'RADIO' as WorkItemDefinitionADTSchemaTypeEnum,
        Select: 'SELECT' as WorkItemDefinitionADTSchemaTypeEnum,
        MultiSelect: 'MULTI_SELECT' as WorkItemDefinitionADTSchemaTypeEnum,
        Number: 'NUMBER' as WorkItemDefinitionADTSchemaTypeEnum,
        EmailAddress: 'EMAIL_ADDRESS' as WorkItemDefinitionADTSchemaTypeEnum,
        File: 'FILE' as WorkItemDefinitionADTSchemaTypeEnum,
        Table: 'TABLE' as WorkItemDefinitionADTSchemaTypeEnum,
        Calendar: 'CALENDAR' as WorkItemDefinitionADTSchemaTypeEnum
    };
}


