/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { NameIdPojo } from '../model/models';
import { ProcessModelDTO } from '../model/models';
import { QueryResultsProcessModelDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateProcessModelRequestParams {
    processModelDTO: ProcessModelDTO;
}

export interface DeleteProcessModelRequestParams {
    id: number;
}

export interface GetProcessExecutionInstanceRequestParams {
    processModelId: number;
}

export interface GetProcessModelListRequestParams {
    departmentId?: number;
}

export interface Search1RequestParams {
    identifier?: string;
    lastUpdatedBy?: string;
    code?: string;
    lastUpdatedAt?: string;
    reviewedAt?: string;
    reviewer?: string;
    reasonForReview?: string;
    portalAccount?: string;
    createdAt?: string;
    modelGroup?: string;
    createdBy?: string;
    name?: string;
    reviewStatus?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    id?: number;
    department?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    departmentId?: number;
    limit?: number;
    keyword?: string;
    priorityItems?: Array<object>;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface UpdateProcessModelRequestParams {
    id: number;
    processModelDTO: ProcessModelDTO;
}


@Injectable({
  providedIn: 'root'
})
export class ProcessModelControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProcessModel(requestParameters: CreateProcessModelRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ProcessModelDTO>;
    public createProcessModel(requestParameters: CreateProcessModelRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ProcessModelDTO>>;
    public createProcessModel(requestParameters: CreateProcessModelRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ProcessModelDTO>>;
    public createProcessModel(requestParameters: CreateProcessModelRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const processModelDTO = requestParameters.processModelDTO;
        if (processModelDTO === null || processModelDTO === undefined) {
            throw new Error('Required parameter processModelDTO was null or undefined when calling createProcessModel.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProcessModelDTO>(`${this.configuration.basePath}/process-models`,
            processModelDTO,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProcessModel(requestParameters: DeleteProcessModelRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public deleteProcessModel(requestParameters: DeleteProcessModelRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public deleteProcessModel(requestParameters: DeleteProcessModelRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public deleteProcessModel(requestParameters: DeleteProcessModelRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteProcessModel.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/process-models/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessExecutionInstance(requestParameters: GetProcessExecutionInstanceRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<NameIdPojo>>;
    public getProcessExecutionInstance(requestParameters: GetProcessExecutionInstanceRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<NameIdPojo>>>;
    public getProcessExecutionInstance(requestParameters: GetProcessExecutionInstanceRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<NameIdPojo>>>;
    public getProcessExecutionInstance(requestParameters: GetProcessExecutionInstanceRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const processModelId = requestParameters.processModelId;
        if (processModelId === null || processModelId === undefined) {
            throw new Error('Required parameter processModelId was null or undefined when calling getProcessExecutionInstance.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (processModelId !== undefined && processModelId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>processModelId, 'processModelId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<NameIdPojo>>(`${this.configuration.basePath}/process-models/get-all-process-execution`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessModelList(requestParameters: GetProcessModelListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<ProcessModelDTO>>;
    public getProcessModelList(requestParameters: GetProcessModelListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<ProcessModelDTO>>>;
    public getProcessModelList(requestParameters: GetProcessModelListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<ProcessModelDTO>>>;
    public getProcessModelList(requestParameters: GetProcessModelListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const departmentId = requestParameters.departmentId;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (departmentId !== undefined && departmentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>departmentId, 'departmentId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProcessModelDTO>>(`${this.configuration.basePath}/process-models/get-all`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search1(requestParameters: Search1RequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsProcessModelDTO>;
    public search1(requestParameters: Search1RequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsProcessModelDTO>>;
    public search1(requestParameters: Search1RequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsProcessModelDTO>>;
    public search1(requestParameters: Search1RequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const identifier = requestParameters.identifier;
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const code = requestParameters.code;
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const reviewedAt = requestParameters.reviewedAt;
        const reviewer = requestParameters.reviewer;
        const reasonForReview = requestParameters.reasonForReview;
        const portalAccount = requestParameters.portalAccount;
        const createdAt = requestParameters.createdAt;
        const modelGroup = requestParameters.modelGroup;
        const createdBy = requestParameters.createdBy;
        const name = requestParameters.name;
        const reviewStatus = requestParameters.reviewStatus;
        const id = requestParameters.id;
        const department = requestParameters.department;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const departmentId = requestParameters.departmentId;
        const limit = requestParameters.limit;
        const keyword = requestParameters.keyword;
        const priorityItems = requestParameters.priorityItems;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (code !== undefined && code !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>code, 'code');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (reviewedAt !== undefined && reviewedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewedAt, 'reviewedAt');
        }
        if (reviewer !== undefined && reviewer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewer, 'reviewer');
        }
        if (reasonForReview !== undefined && reasonForReview !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reasonForReview, 'reasonForReview');
        }
        if (portalAccount !== undefined && portalAccount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccount, 'portalAccount');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (modelGroup !== undefined && modelGroup !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>modelGroup, 'modelGroup');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (reviewStatus !== undefined && reviewStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewStatus, 'reviewStatus');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (department !== undefined && department !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>department, 'department');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (departmentId !== undefined && departmentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>departmentId, 'departmentId');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsProcessModelDTO>(`${this.configuration.basePath}/process-models`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProcessModel(requestParameters: UpdateProcessModelRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ProcessModelDTO>;
    public updateProcessModel(requestParameters: UpdateProcessModelRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ProcessModelDTO>>;
    public updateProcessModel(requestParameters: UpdateProcessModelRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ProcessModelDTO>>;
    public updateProcessModel(requestParameters: UpdateProcessModelRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateProcessModel.');
        }
        const processModelDTO = requestParameters.processModelDTO;
        if (processModelDTO === null || processModelDTO === undefined) {
            throw new Error('Required parameter processModelDTO was null or undefined when calling updateProcessModel.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<ProcessModelDTO>(`${this.configuration.basePath}/process-models/${encodeURIComponent(String(id))}`,
            processModelDTO,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
