<div class="row">
    <div class="col-12">
        <form *ngIf="showFilter" [formGroup]="form">
            <div class="my-md-5 my-3 p-3 bg-white row align-items-center search justify-content-between">
                <div class="col-md-5 mt-0">
                    <div class="material-form">
                        <input type="text" class="form-control" [formControlName]="'keyword'"
                               placeholder="Search by Unit Name, any Unit Keyword">
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="d-flex align-items-center float-end">
                        <button class="btn btn-dark ms-3 px-3 d-flex align-items-center" (click)="submit()"><span
                                class="iconify icon me-2"
                                data-icon="iconamoon:search-thin"></span>Search
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div class="my-1">
            <div *ngIf="searchManager.totalCount > 0" class="row"
                 [ngClass]="{'disabled-table': searchManager.working}">
                <div class="d-md-flex align-items-center justify-content-between">
                    <div class="col-md-6">
                        <app-total-records [searchManager]="searchManager"></app-total-records>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end">
                        <page-size
                                *ngIf="searchManager.totalCount > 0"
                                [searchManager]="searchManager"
                        ></page-size>
                    </div>
                </div>
            </div>
        </div>
        <div class="card search" style="border: 0.2px solid #ececec;">
            <div class="card-body">
                <div class="table-responsive table-borderless">
                    <table class="table table-striped">
                        <thead class="text-table-header fw-bold">
                        <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">Module</th>
                            <th scope="col">Production Unit Type</th>
                            <th scope="col">Production Unit Name</th>
                            <th *ngIf="showReleaseStatus" scope="col">Release Status</th>
                        </tr>
                        </thead>
                        <tbody class="text-table-body">
                        <ng-container>
                            <ng-container *ngIf="searchManager.list.length > 0; else noData">
                                <tr class="th-1" *ngFor="let item of searchManager.list; let k = index">
                                    <td data-label="S/N">{{ k + searchManager.offset + 1 }}</td>
                                    <td data-label="Module Name">{{ item?.softwareModule?.name | titlecase }}</td>
                                    <td data-label="Unit Type">{{ item?.softwareUnitType?.name | uppercase }}</td>
                                    <td data-label="Unit Name">{{ helperService.cutText(item?.name, 40) }}
                                        <span *ngIf="item?.name?.length >= 41" [placement]="'bottom'"
                                              [tooltip]="item?.name">
                                                <span class="iconify text-black pointer"
                                                      data-icon="material-symbols:info">
                                                </span>
                                            </span>
                                    <td *ngIf="showReleaseStatus" data-label="status">
                                        <ng-container *ngIf="item.completionStatus == 'COMPLETED'">
                                            <div class="text-success">
                                                {{ 'Released' }}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!item.completionStatus || item.completionStatus == 'NOT_COMPLETED'">
                                            <div class="text-danger">
                                                {{ 'Not Released' }}
                                            </div>
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>

                        <ng-template #searching>
                            <tr>
                                <td colspan="100%">
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template #noData>
                            <tr>
                                <td class="text-center" colspan="9">NO DATA</td>
                            </tr>
                        </ng-template>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="searchManager.totalCount > 10 && !searchManager.working" class="row py-2">
            <div class="col-12 d-flex justify-content-end">
                <pagination
                        [(ngModel)]="searchManager.page"
                        [boundaryLinks]="true"
                        [itemsPerPage]="searchManager.itemsPerPage"
                        [maxSize]="maxPageSize"
                        [rotate]="true"
                        [totalItems]="searchManager.totalCount"
                        firstText="&laquo;"
                        lastText="&raquo;"
                        nextText="&rsaquo;"
                        previousText="&lsaquo;"
                ></pagination>
            </div>
        </div>
    </div>
</div>