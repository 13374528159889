/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ActivityChecklistItemCommentDto } from '../model/models';
import { ActivityChecklistItemCommentPojo } from '../model/models';
import { ActivityChecklistItemDto } from '../model/models';
import { ActivityChecklistItemPojo } from '../model/models';
import { ActivityChecklistItemStatusPojo } from '../model/models';
import { ActivityChecklistItemStatusUpdateDto } from '../model/models';
import { ActivityOwnerOrganisationDto } from '../model/models';
import { ActivityOwnerOrganisationPojo } from '../model/models';
import { ActivityOwnerPlayer } from '../model/models';
import { QueryResultsActivityChecklistItemPojo } from '../model/models';
import { QueryResultsActivityOwnerOrganisationPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CompleteChecklistItemRequestParams {
    id: number;
}

export interface CreateActivityChecklistItemRequestParams {
    activityChecklistItemDto: ActivityChecklistItemDto;
}

export interface CreateActivityChecklistItemCommentRequestParams {
    id: number;
    activityChecklistItemCommentDto: ActivityChecklistItemCommentDto;
}

export interface CreateActivityOwnerOrganisationRequestParams {
    activityOwnerOrganisationDto: ActivityOwnerOrganisationDto;
}

export interface DeleteActivityChecklistItemStatusDocumentRequestParams {
    id: number;
    documentId: number;
}

export interface DeleteActivityChecklistitemRequestParams {
    id: number;
}

export interface DeleteActivityOwnerOrganisationRequestParams {
    activityOwnerOrganisationId: number;
}

export interface DeleteActivityOwnerOrganisationPlayerRequestParams {
    activityOwnerOrganisationId: number;
    playerId: number;
}

export interface GetAllActivityOwnerPlayersRequestParams {
    responsiblePlayerIds: Array<number>;
}

export interface GetAllCommentRequestParams {
    id: number;
}

export interface GetStatusHistoryRequestParams {
    id: number;
}

export interface RemovePlayerFromActivityChecklistItemRequestParams {
    activityOwnerOrganisationId: number;
    playerId: number;
}

export interface SearchActivityChecklistItemRequestParams {
    customerValue?: string;
    actualStartTime?: string;
    customerValueId?: number;
    activity?: string;
    milestoneId?: number;
    dueTime?: string;
    completionTime?: string;
    score?: number;
    createdAt?: string;
    createdBy?: string;
    expectedStartTime?: string;
    activityStatus?: 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE';
    activityMilestone?: string;
    id?: number;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    startDateTo?: string;
    createdAfter?: string;
    priorityItems?: Array<object>;
    dueDateTo?: string;
    checklistIds?: Array<object>;
    startDateFrom?: string;
    limit?: number;
    dueDateFrom?: string;
    createdBefore?: string;
    completionDateTo?: string;
    keyword?: string;
    completionDateFrom?: string;
    activityChecklistStatus?: 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE';
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface SearchActivityOwnerOrganisationsRequestParams {
    portalAccount?: string;
    createdAt?: string;
    internal?: boolean;
    createdBy?: string;
    name?: string;
    id?: number;
    shortName?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    limit?: number;
    ids?: Array<object>;
    createdBefore?: string;
    createdAfter?: string;
    priorityItems?: Array<object>;
    keyword?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface StartChecklistItemRequestParams {
    id: number;
}

export interface UpdateActivityChecklistItemRequestParams {
    id: number;
    activityChecklistItemDto: ActivityChecklistItemDto;
}

export interface UpdateActivityChecklistItemScoreRequestParams {
    id: number;
    score: number;
}

export interface UpdateActivityChecklistItemStatusRequestParams {
    activityChecklistItemStatusUpdateDto: ActivityChecklistItemStatusUpdateDto;
}

export interface UpdateActivityOwnerOrganisationRequestParams {
    activityOwnerOrganisationId: number;
    activityOwnerOrganisationDto: ActivityOwnerOrganisationDto;
}


@Injectable({
  providedIn: 'root'
})
export class ActivityChecklistItemControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Complete Activity Checklist Item
     * Complete Activity Checklist Item
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public completeChecklistItem(requestParameters: CompleteChecklistItemRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public completeChecklistItem(requestParameters: CompleteChecklistItemRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public completeChecklistItem(requestParameters: CompleteChecklistItemRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public completeChecklistItem(requestParameters: CompleteChecklistItemRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling completeChecklistItem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<object>(`${this.configuration.basePath}/activity-checklist-items/${encodeURIComponent(String(id))}/complete`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Activity Checklist Item
     * Create Activity Checklist Item
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createActivityChecklistItem(requestParameters: CreateActivityChecklistItemRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ActivityChecklistItemPojo>;
    public createActivityChecklistItem(requestParameters: CreateActivityChecklistItemRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ActivityChecklistItemPojo>>;
    public createActivityChecklistItem(requestParameters: CreateActivityChecklistItemRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ActivityChecklistItemPojo>>;
    public createActivityChecklistItem(requestParameters: CreateActivityChecklistItemRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const activityChecklistItemDto = requestParameters.activityChecklistItemDto;
        if (activityChecklistItemDto === null || activityChecklistItemDto === undefined) {
            throw new Error('Required parameter activityChecklistItemDto was null or undefined when calling createActivityChecklistItem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ActivityChecklistItemPojo>(`${this.configuration.basePath}/activity-checklist-items`,
            activityChecklistItemDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Activity Checklist Item Comment
     * Create Activity Checklist Item Comment
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createActivityChecklistItemComment(requestParameters: CreateActivityChecklistItemCommentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ActivityChecklistItemCommentPojo>;
    public createActivityChecklistItemComment(requestParameters: CreateActivityChecklistItemCommentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ActivityChecklistItemCommentPojo>>;
    public createActivityChecklistItemComment(requestParameters: CreateActivityChecklistItemCommentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ActivityChecklistItemCommentPojo>>;
    public createActivityChecklistItemComment(requestParameters: CreateActivityChecklistItemCommentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling createActivityChecklistItemComment.');
        }
        const activityChecklistItemCommentDto = requestParameters.activityChecklistItemCommentDto;
        if (activityChecklistItemCommentDto === null || activityChecklistItemCommentDto === undefined) {
            throw new Error('Required parameter activityChecklistItemCommentDto was null or undefined when calling createActivityChecklistItemComment.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ActivityChecklistItemCommentPojo>(`${this.configuration.basePath}/activity-checklist-items/${encodeURIComponent(String(id))}/comment`,
            activityChecklistItemCommentDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Activity Owner Organisation
     * Create Activity Owner Organisation
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createActivityOwnerOrganisation(requestParameters: CreateActivityOwnerOrganisationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ActivityOwnerOrganisationPojo>;
    public createActivityOwnerOrganisation(requestParameters: CreateActivityOwnerOrganisationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ActivityOwnerOrganisationPojo>>;
    public createActivityOwnerOrganisation(requestParameters: CreateActivityOwnerOrganisationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ActivityOwnerOrganisationPojo>>;
    public createActivityOwnerOrganisation(requestParameters: CreateActivityOwnerOrganisationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const activityOwnerOrganisationDto = requestParameters.activityOwnerOrganisationDto;
        if (activityOwnerOrganisationDto === null || activityOwnerOrganisationDto === undefined) {
            throw new Error('Required parameter activityOwnerOrganisationDto was null or undefined when calling createActivityOwnerOrganisation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ActivityOwnerOrganisationPojo>(`${this.configuration.basePath}/activity-checklist-items/create-activity-owner-organisation`,
            activityOwnerOrganisationDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Activity Checklist Item Status History
     * Get Activity Checklist Item Status History
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityChecklistItemStatusDocument(requestParameters: DeleteActivityChecklistItemStatusDocumentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public deleteActivityChecklistItemStatusDocument(requestParameters: DeleteActivityChecklistItemStatusDocumentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public deleteActivityChecklistItemStatusDocument(requestParameters: DeleteActivityChecklistItemStatusDocumentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public deleteActivityChecklistItemStatusDocument(requestParameters: DeleteActivityChecklistItemStatusDocumentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteActivityChecklistItemStatusDocument.');
        }
        const documentId = requestParameters.documentId;
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling deleteActivityChecklistItemStatusDocument.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activity-checklist-items/${encodeURIComponent(String(id))}/delete-status-document/${encodeURIComponent(String(documentId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Activity Checklist Item
     * Delete Activity Checklist Item
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityChecklistitem(requestParameters: DeleteActivityChecklistitemRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public deleteActivityChecklistitem(requestParameters: DeleteActivityChecklistitemRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public deleteActivityChecklistitem(requestParameters: DeleteActivityChecklistitemRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public deleteActivityChecklistitem(requestParameters: DeleteActivityChecklistitemRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteActivityChecklistitem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/activity-checklist-items/${encodeURIComponent(String(id))}/delete`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Activity Owner Organisation
     * Delete Activity Owner Organisation
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityOwnerOrganisation(requestParameters: DeleteActivityOwnerOrganisationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public deleteActivityOwnerOrganisation(requestParameters: DeleteActivityOwnerOrganisationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public deleteActivityOwnerOrganisation(requestParameters: DeleteActivityOwnerOrganisationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public deleteActivityOwnerOrganisation(requestParameters: DeleteActivityOwnerOrganisationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const activityOwnerOrganisationId = requestParameters.activityOwnerOrganisationId;
        if (activityOwnerOrganisationId === null || activityOwnerOrganisationId === undefined) {
            throw new Error('Required parameter activityOwnerOrganisationId was null or undefined when calling deleteActivityOwnerOrganisation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activity-checklist-items/delete-activity-owner-organisation/${encodeURIComponent(String(activityOwnerOrganisationId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Activity Owner Organisation Player
     * Delete Activity Owner Organisation
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityOwnerOrganisationPlayer(requestParameters: DeleteActivityOwnerOrganisationPlayerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public deleteActivityOwnerOrganisationPlayer(requestParameters: DeleteActivityOwnerOrganisationPlayerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public deleteActivityOwnerOrganisationPlayer(requestParameters: DeleteActivityOwnerOrganisationPlayerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public deleteActivityOwnerOrganisationPlayer(requestParameters: DeleteActivityOwnerOrganisationPlayerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const activityOwnerOrganisationId = requestParameters.activityOwnerOrganisationId;
        if (activityOwnerOrganisationId === null || activityOwnerOrganisationId === undefined) {
            throw new Error('Required parameter activityOwnerOrganisationId was null or undefined when calling deleteActivityOwnerOrganisationPlayer.');
        }
        const playerId = requestParameters.playerId;
        if (playerId === null || playerId === undefined) {
            throw new Error('Required parameter playerId was null or undefined when calling deleteActivityOwnerOrganisationPlayer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activity-checklist-items/delete-activity-owner-organisation/${encodeURIComponent(String(activityOwnerOrganisationId))}/player/${encodeURIComponent(String(playerId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Players in ActivityChecklistItem Player
     * Get Players in ActivityChecklistItem Player
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllActivityOwnerPlayers(requestParameters: GetAllActivityOwnerPlayersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<ActivityOwnerPlayer>>;
    public getAllActivityOwnerPlayers(requestParameters: GetAllActivityOwnerPlayersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<ActivityOwnerPlayer>>>;
    public getAllActivityOwnerPlayers(requestParameters: GetAllActivityOwnerPlayersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<ActivityOwnerPlayer>>>;
    public getAllActivityOwnerPlayers(requestParameters: GetAllActivityOwnerPlayersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const responsiblePlayerIds = requestParameters.responsiblePlayerIds;
        if (responsiblePlayerIds === null || responsiblePlayerIds === undefined) {
            throw new Error('Required parameter responsiblePlayerIds was null or undefined when calling getAllActivityOwnerPlayers.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (responsiblePlayerIds) {
            responsiblePlayerIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'responsiblePlayerIds');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ActivityOwnerPlayer>>(`${this.configuration.basePath}/activity-checklist-items/players`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Activity Checklist Item Comments
     * Get All Activity Checklist Item Comments
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllComment(requestParameters: GetAllCommentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<ActivityChecklistItemCommentPojo>>;
    public getAllComment(requestParameters: GetAllCommentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<ActivityChecklistItemCommentPojo>>>;
    public getAllComment(requestParameters: GetAllCommentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<ActivityChecklistItemCommentPojo>>>;
    public getAllComment(requestParameters: GetAllCommentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAllComment.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ActivityChecklistItemCommentPojo>>(`${this.configuration.basePath}/activity-checklist-items/${encodeURIComponent(String(id))}/comment`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Activity Checklist Item Status History
     * Get Activity Checklist Item Status History
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStatusHistory(requestParameters: GetStatusHistoryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<ActivityChecklistItemStatusPojo>>;
    public getStatusHistory(requestParameters: GetStatusHistoryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<ActivityChecklistItemStatusPojo>>>;
    public getStatusHistory(requestParameters: GetStatusHistoryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<ActivityChecklistItemStatusPojo>>>;
    public getStatusHistory(requestParameters: GetStatusHistoryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getStatusHistory.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ActivityChecklistItemStatusPojo>>(`${this.configuration.basePath}/activity-checklist-items/${encodeURIComponent(String(id))}/get-status-history`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Activity Owner Organisation Player
     * Delete Activity Owner Organisation
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removePlayerFromActivityChecklistItem(requestParameters: RemovePlayerFromActivityChecklistItemRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public removePlayerFromActivityChecklistItem(requestParameters: RemovePlayerFromActivityChecklistItemRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public removePlayerFromActivityChecklistItem(requestParameters: RemovePlayerFromActivityChecklistItemRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public removePlayerFromActivityChecklistItem(requestParameters: RemovePlayerFromActivityChecklistItemRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const activityOwnerOrganisationId = requestParameters.activityOwnerOrganisationId;
        if (activityOwnerOrganisationId === null || activityOwnerOrganisationId === undefined) {
            throw new Error('Required parameter activityOwnerOrganisationId was null or undefined when calling removePlayerFromActivityChecklistItem.');
        }
        const playerId = requestParameters.playerId;
        if (playerId === null || playerId === undefined) {
            throw new Error('Required parameter playerId was null or undefined when calling removePlayerFromActivityChecklistItem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/activity-checklist-items/remove-player-from-activity/${encodeURIComponent(String(activityOwnerOrganisationId))}/player/${encodeURIComponent(String(playerId))}`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Activity Checklist Item 
     * Search Activity Checklist Item
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchActivityChecklistItem(requestParameters: SearchActivityChecklistItemRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsActivityChecklistItemPojo>;
    public searchActivityChecklistItem(requestParameters: SearchActivityChecklistItemRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsActivityChecklistItemPojo>>;
    public searchActivityChecklistItem(requestParameters: SearchActivityChecklistItemRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsActivityChecklistItemPojo>>;
    public searchActivityChecklistItem(requestParameters: SearchActivityChecklistItemRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const customerValue = requestParameters.customerValue;
        const actualStartTime = requestParameters.actualStartTime;
        const customerValueId = requestParameters.customerValueId;
        const activity = requestParameters.activity;
        const milestoneId = requestParameters.milestoneId;
        const dueTime = requestParameters.dueTime;
        const completionTime = requestParameters.completionTime;
        const score = requestParameters.score;
        const createdAt = requestParameters.createdAt;
        const createdBy = requestParameters.createdBy;
        const expectedStartTime = requestParameters.expectedStartTime;
        const activityStatus = requestParameters.activityStatus;
        const activityMilestone = requestParameters.activityMilestone;
        const id = requestParameters.id;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const startDateTo = requestParameters.startDateTo;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const dueDateTo = requestParameters.dueDateTo;
        const checklistIds = requestParameters.checklistIds;
        const startDateFrom = requestParameters.startDateFrom;
        const limit = requestParameters.limit;
        const dueDateFrom = requestParameters.dueDateFrom;
        const createdBefore = requestParameters.createdBefore;
        const completionDateTo = requestParameters.completionDateTo;
        const keyword = requestParameters.keyword;
        const completionDateFrom = requestParameters.completionDateFrom;
        const activityChecklistStatus = requestParameters.activityChecklistStatus;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (customerValue !== undefined && customerValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValue, 'customerValue');
        }
        if (actualStartTime !== undefined && actualStartTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actualStartTime, 'actualStartTime');
        }
        if (customerValueId !== undefined && customerValueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValueId, 'customerValueId');
        }
        if (activity !== undefined && activity !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>activity, 'activity');
        }
        if (milestoneId !== undefined && milestoneId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>milestoneId, 'milestoneId');
        }
        if (dueTime !== undefined && dueTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dueTime, 'dueTime');
        }
        if (completionTime !== undefined && completionTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completionTime, 'completionTime');
        }
        if (score !== undefined && score !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>score, 'score');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (expectedStartTime !== undefined && expectedStartTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>expectedStartTime, 'expectedStartTime');
        }
        if (activityStatus !== undefined && activityStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>activityStatus, 'activityStatus');
        }
        if (activityMilestone !== undefined && activityMilestone !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>activityMilestone, 'activityMilestone');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (startDateTo !== undefined && startDateTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startDateTo, 'startDateTo');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (dueDateTo !== undefined && dueDateTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dueDateTo, 'dueDateTo');
        }
        if (checklistIds) {
            checklistIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'checklistIds');
            })
        }
        if (startDateFrom !== undefined && startDateFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startDateFrom, 'startDateFrom');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (dueDateFrom !== undefined && dueDateFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dueDateFrom, 'dueDateFrom');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (completionDateTo !== undefined && completionDateTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completionDateTo, 'completionDateTo');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (completionDateFrom !== undefined && completionDateFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completionDateFrom, 'completionDateFrom');
        }
        if (activityChecklistStatus !== undefined && activityChecklistStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>activityChecklistStatus, 'activityChecklistStatus');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsActivityChecklistItemPojo>(`${this.configuration.basePath}/activity-checklist-items/search`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Activity Owner Organisations
     * Search Activity Owner Organisations
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchActivityOwnerOrganisations(requestParameters: SearchActivityOwnerOrganisationsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsActivityOwnerOrganisationPojo>;
    public searchActivityOwnerOrganisations(requestParameters: SearchActivityOwnerOrganisationsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsActivityOwnerOrganisationPojo>>;
    public searchActivityOwnerOrganisations(requestParameters: SearchActivityOwnerOrganisationsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsActivityOwnerOrganisationPojo>>;
    public searchActivityOwnerOrganisations(requestParameters: SearchActivityOwnerOrganisationsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const portalAccount = requestParameters.portalAccount;
        const createdAt = requestParameters.createdAt;
        const internal = requestParameters.internal;
        const createdBy = requestParameters.createdBy;
        const name = requestParameters.name;
        const id = requestParameters.id;
        const shortName = requestParameters.shortName;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const ids = requestParameters.ids;
        const createdBefore = requestParameters.createdBefore;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const keyword = requestParameters.keyword;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (portalAccount !== undefined && portalAccount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccount, 'portalAccount');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (internal !== undefined && internal !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>internal, 'internal');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (shortName !== undefined && shortName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>shortName, 'shortName');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (ids) {
            ids.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'ids');
            })
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsActivityOwnerOrganisationPojo>(`${this.configuration.basePath}/activity-checklist-items/search-activity-owner-organisation`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Start Activity Checklist Item
     * Start Activity Checklist Item
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startChecklistItem(requestParameters: StartChecklistItemRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public startChecklistItem(requestParameters: StartChecklistItemRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public startChecklistItem(requestParameters: StartChecklistItemRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public startChecklistItem(requestParameters: StartChecklistItemRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling startChecklistItem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<object>(`${this.configuration.basePath}/activity-checklist-items/${encodeURIComponent(String(id))}/start`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Activity Checklist Item
     * Update Activity Checklist Item
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityChecklistItem(requestParameters: UpdateActivityChecklistItemRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ActivityChecklistItemPojo>;
    public updateActivityChecklistItem(requestParameters: UpdateActivityChecklistItemRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ActivityChecklistItemPojo>>;
    public updateActivityChecklistItem(requestParameters: UpdateActivityChecklistItemRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ActivityChecklistItemPojo>>;
    public updateActivityChecklistItem(requestParameters: UpdateActivityChecklistItemRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateActivityChecklistItem.');
        }
        const activityChecklistItemDto = requestParameters.activityChecklistItemDto;
        if (activityChecklistItemDto === null || activityChecklistItemDto === undefined) {
            throw new Error('Required parameter activityChecklistItemDto was null or undefined when calling updateActivityChecklistItem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<ActivityChecklistItemPojo>(`${this.configuration.basePath}/activity-checklist-items/${encodeURIComponent(String(id))}/edit`,
            activityChecklistItemDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Activity Owner Organisation
     * Delete Activity Owner Organisation
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityChecklistItemScore(requestParameters: UpdateActivityChecklistItemScoreRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ActivityChecklistItemPojo>;
    public updateActivityChecklistItemScore(requestParameters: UpdateActivityChecklistItemScoreRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ActivityChecklistItemPojo>>;
    public updateActivityChecklistItemScore(requestParameters: UpdateActivityChecklistItemScoreRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ActivityChecklistItemPojo>>;
    public updateActivityChecklistItemScore(requestParameters: UpdateActivityChecklistItemScoreRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateActivityChecklistItemScore.');
        }
        const score = requestParameters.score;
        if (score === null || score === undefined) {
            throw new Error('Required parameter score was null or undefined when calling updateActivityChecklistItemScore.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (score !== undefined && score !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>score, 'score');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<ActivityChecklistItemPojo>(`${this.configuration.basePath}/activity-checklist-items/${encodeURIComponent(String(id))}/update-score`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Complete Activity Checklist Item
     * Complete Activity Checklist Item
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityChecklistItemStatus(requestParameters: UpdateActivityChecklistItemStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public updateActivityChecklistItemStatus(requestParameters: UpdateActivityChecklistItemStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public updateActivityChecklistItemStatus(requestParameters: UpdateActivityChecklistItemStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public updateActivityChecklistItemStatus(requestParameters: UpdateActivityChecklistItemStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const activityChecklistItemStatusUpdateDto = requestParameters.activityChecklistItemStatusUpdateDto;
        if (activityChecklistItemStatusUpdateDto === null || activityChecklistItemStatusUpdateDto === undefined) {
            throw new Error('Required parameter activityChecklistItemStatusUpdateDto was null or undefined when calling updateActivityChecklistItemStatus.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<object>(`${this.configuration.basePath}/activity-checklist-items/update-status`,
            activityChecklistItemStatusUpdateDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Activity Owner Organisation
     * Update Activity Owner Organisation
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityOwnerOrganisation(requestParameters: UpdateActivityOwnerOrganisationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ActivityOwnerOrganisationPojo>;
    public updateActivityOwnerOrganisation(requestParameters: UpdateActivityOwnerOrganisationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ActivityOwnerOrganisationPojo>>;
    public updateActivityOwnerOrganisation(requestParameters: UpdateActivityOwnerOrganisationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ActivityOwnerOrganisationPojo>>;
    public updateActivityOwnerOrganisation(requestParameters: UpdateActivityOwnerOrganisationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const activityOwnerOrganisationId = requestParameters.activityOwnerOrganisationId;
        if (activityOwnerOrganisationId === null || activityOwnerOrganisationId === undefined) {
            throw new Error('Required parameter activityOwnerOrganisationId was null or undefined when calling updateActivityOwnerOrganisation.');
        }
        const activityOwnerOrganisationDto = requestParameters.activityOwnerOrganisationDto;
        if (activityOwnerOrganisationDto === null || activityOwnerOrganisationDto === undefined) {
            throw new Error('Required parameter activityOwnerOrganisationDto was null or undefined when calling updateActivityOwnerOrganisation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ActivityOwnerOrganisationPojo>(`${this.configuration.basePath}/activity-checklist-items/${encodeURIComponent(String(activityOwnerOrganisationId))}/update-activity-owner-organisation`,
            activityOwnerOrganisationDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
