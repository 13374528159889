/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactInfoDto } from './contact-info-dto';
import { IncidentResponseStepPojo } from './incident-response-step-pojo';
import { ContactDto } from './contact-dto';
import { IncidentImpactPojo } from './incident-impact-pojo';
import { PermanentResolutionMeasurePojo } from './permanent-resolution-measure-pojo';
import { IncidentDetailsDto } from './incident-details-dto';


export interface IncidentLogDto { 
    recipients?: Array<ContactInfoDto>;
    contact?: ContactDto;
    incidentImpact?: Array<IncidentImpactPojo>;
    incidentDetails?: IncidentDetailsDto;
    platformId?: number;
    resolutionStatus?: IncidentLogDto.ResolutionStatusEnum;
    impactStep?: Array<IncidentResponseStepPojo>;
    measure?: Array<PermanentResolutionMeasurePojo>;
    occuredAt?: string;
    resolvedDate?: string;
    note?: string;
    reviewStatus?: IncidentLogDto.ReviewStatusEnum;
    selectedApproverIds?: Array<number>;
}
export namespace IncidentLogDto {
    export type ResolutionStatusEnum = 'PENDING' | 'IN_PROGRESS' | 'RESOLVED' | 'CANCELLED';
    export const ResolutionStatusEnum = {
        Pending: 'PENDING' as ResolutionStatusEnum,
        InProgress: 'IN_PROGRESS' as ResolutionStatusEnum,
        Resolved: 'RESOLVED' as ResolutionStatusEnum,
        Cancelled: 'CANCELLED' as ResolutionStatusEnum
    };
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        Approved: 'APPROVED' as ReviewStatusEnum,
        Pending: 'PENDING' as ReviewStatusEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusEnum
    };
}


