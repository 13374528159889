/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachment-dto';


export interface WorkProductDefinitionDto { 
    id?: number;
    name: string;
    footerInfo?: string;
    logo?: AttachmentDto;
    versionNumberDisplayName?: string;
    approverRoles?: Array<number>;
    standards?: Array<number>;
    purposeAnnotations?: Array<WorkProductDefinitionDto.PurposeAnnotationsEnum>;
}
export namespace WorkProductDefinitionDto {
    export type PurposeAnnotationsEnum = 'USER_ACCEPTANCE' | 'QUALITY_ASSURANCE' | 'DOCUMENTATION';
    export const PurposeAnnotationsEnum = {
        UserAcceptance: 'USER_ACCEPTANCE' as PurposeAnnotationsEnum,
        QualityAssurance: 'QUALITY_ASSURANCE' as PurposeAnnotationsEnum,
        Documentation: 'DOCUMENTATION' as PurposeAnnotationsEnum
    };
}


