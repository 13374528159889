<div class="main-header">

  <nav>
    <div class="left ">
      <button class="menu-toggler" (click)="setToggle()">
        <i class="iconify icon" data-icon="material-symbols-light:menu"></i>
      </button>
        <app-platform-switcher-button *ngIf="showPlatformSwitch"></app-platform-switcher-button>
    </div>
    <div class="right">
      <nav>
        <div class="header-top-section">
          <div class="header-top-right-container d-flex gap-2 justify-content-between align-items-center">
            <div class="dropdown">
              <button
                class="btn btn-sm"
                type="button"
                (click)="showToast()"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="notification-bell">
                  <span class="count" *ngIf="notificationCount > 0">{{ notificationCount }}</span>
                  <span
                    class="iconify icon"
                    [attr.data-icon]="
                      notificationCount > 0 ? 'basil:notification-solid' : 'basil:notification-outline'
                    "
                    style="color: black;"
                  ></span>
                </span>
              </button>
              <div class="overlay" *ngIf="showNotifications" (click)="showNotifications = false">
              </div>
              <ngb-toast
              class="mt-2"
                (hidden)="showNotifications = false"
                *ngIf="showNotifications"
                [autohide]="false"
                [delay]="5000"
                id="notifications">
                <div class="toast-header">
                  <strong class="me-auto">{{ 'Notifications' }}</strong>
                  <div>
                    <button
                      (click)="showToast()"
                      class="btn-close"
                      type="button"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>
                <ng-container *ngIf="notifications.length > 0">
                  <div class="notification-container">
                    <div
                      class="card text-dark my-2 mt-3"
                      *ngFor="let req of notifications; let rowNum = index"
                    >
                      <a (click)="viewRequest(req)" class="text-decoration-none pointer">
                        <div
                          class="card-header bg-transparent justify-content-between d-flex text-muted"
                          style="background-color: #F0F0F0 !important;">
                          <p class="mb-0" style="color: rgba(0, 0, 0, 0.80) !important;">
                            <strong class="me-auto">{{ req.title }}</strong>
                          </p>
                          <p class="mb-0">
                            <small>{{ req.sentAt | datetime }}</small>
                          </p>
                        </div>
                        <div class="card-body" style="padding: 10px;">
                          <div class="d-flex justify-content-start align-items-center gap-3">
                            <div class="d-flex justify-content-center align-items-center" 
                            style="min-width: 40px; 
                            min-height: 40px;
                            width: 40px;
                            height: 40px;
                            border-radius: 100%;
                            border: 1px solid rgba(95, 95, 97, 0.10);">
                              <img style="width: 100%; height: 100%;" src="assets/img/cfs-logo.svg" alt="">
                            </div>
                            <p class="card-text mb-0 small text-muted fw-normal text-truncate" 
                              style="color: rgba(0, 0, 0, 0.80) !important;">
                                {{ req.message || '' }}
                              </p>
                          </div>
                          <p class="card-text mb-0 small text-muted fw-normal text-truncate"></p>
                          <div class="d-flex justify-content-between mt-3">
                            <p *ngIf="req.platform?.name" class="mb-0" style="border-radius: 4px;
                            background-color: rgba(95, 95, 97, 0.10);
                            color: rgba(0, 0, 0, 0.70);
                            font-weight: 500;
                            font-size: 12px;
                            padding: 4px 8px;">
                              {{ req.platform?.name }}
                            </p>
                            <p *ngIf="!req.platform?.name"></p>
                            <span (click)="viewDetails(req)" class="view-details">
                              View Details
                              <i class="iconify iconify-icon" data-icon="solar:arrow-right-bold"></i>
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="notifications.length <= 0">
                  <div class="text-dark my-2 mt-3">
                    <p class="card-text mb-0 small text-muted fw-normal text-truncate">
                      No new notifications
                    </p>
                  </div>
                </ng-container>
                <div class="text-center">
                  <a routerLink="work-process/workflow-and-notifications" 
                  fragment="notifications" 
                  (click)="showToast()" 
                  class="btn btn-link"
                  style="text-decoration: none; color: rgba(24, 38, 100, 1);">
                   View all Notifications
                  </a>
                </div>
              </ngb-toast>
            </div>
            <div class="ms-2">
              <button class="btn btn-outline-tertiary text-dark d-inline-flex align-items-center" type="button"
                      data-bs-toggle="dropdown" aria-expanded="false">
                <span class="iconify me-2 text-dark" data-icon="ci:user" style="font-size: 20px;"></span>
                {{ user?.email }}
                <span class="iconify text-dark" data-icon="ci:caret-down" style="font-size: 24px;"></span>
              </button>
              <ul class="dropdown-menu dropdown-menu-end user-dropdown-menu">
                  <li>
                      <a class="dropdown-item pointer" [routerLink]="'/dashboard/profile-management'">
                          <i class="iconify" data-inline="false" data-icon="iconamoon:profile"></i>
                          Profile Update</a>
                  </li>
                <li>
                  <a class="dropdown-item"
                     (click)="logout()"
                  >
                    <span class="iconify me-1 text-danger" data-icon="ci:log-out"
                          style="font-size: 18px;">

                    </span> Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </nav>

</div>
