<div class="card search mt-3">
    <div class="card-body py-4" style="background-color: rgba(64,139,246,0.1);">
        <div class="row">
            <div class="col-3">
                <div
                    class="d-flex flex-column align-content-center justify-content-center gap-2">
                    <label class="pb-2 fw-bold text-center header">Headshot</label>

                    <div class="img-card" *ngIf="this.user?.displayPictureId">
                        <img [src]="getFileUrl(this.user?.displayPictureId)"
                            class="headshot-picture figure-img img-fluid" alt="" />
                    </div>

                    <div *ngIf="!this.user?.displayPictureId">
                        NO UPLOADED HEADSHOT
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div
                    class="d-flex flex-column align-content-center justify-content-center gap-2">
                    <label class="pb-2 fw-bold header text-center">Signature</label>
                    <div id="signature" *ngIf="this.user?.signatureId" class="bg-white">
                        <img class="figure-img img-fluid" [src]="getFileUrl(this.user?.signatureId)" alt="">
                    </div>
                    <div class="mx-auto" *ngIf="!this.user?.signatureId">
                        NO UPLOADED SIGNATURE
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-6 mb-3">
                        <label class="pb-2 fw-bold header" *ngIf="action">{{ action }} By</label>
                        <label class="pb-2 fw-bold header" *ngIf="!action">Player Name</label>
                        <p>{{ this.user?.displayName || '-' | titlecase }}</p>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="pb-2 fw-bold header">Player Role</label>
                        <p>{{ this.user?.designation || '-' | titlecase }}</p>
                    </div>
                </div>
            </div>
            <div>

            </div>
            <div>

            </div>

        </div>
    </div>
</div>
