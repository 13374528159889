/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WorkProductDocumentStyleDto { 
    backgroundColourHex: string;
    borderColourHex: string;
    inputColourHex: string;
    questionColourHex: string;
    footerColourHex: string;
    footerBorderColourHex: string;
    banner: WorkProductDocumentStyleDto.BannerEnum;
}
export namespace WorkProductDocumentStyleDto {
    export type BannerEnum = 'BLUE' | 'ORANGE' | 'GREEN' | 'RED';
    export const BannerEnum = {
        Blue: 'BLUE' as BannerEnum,
        Orange: 'ORANGE' as BannerEnum,
        Green: 'GREEN' as BannerEnum,
        Red: 'RED' as BannerEnum
    };
}


