/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProcessActionDefinitionDto { 
    id?: number;
    name: string;
    ordinal: number;
    baselineRunTimeInMinutes: number;
    verbCommitmentPhrase: string;
    processDefinitionId: number;
    supportedRoles?: Array<number>;
    requiredTools?: Array<number>;
    requiredSkills?: Array<number>;
    requiredCertifications?: Array<number>;
    requiredTrainings?: Array<number>;
    applicableStandards?: Array<number>;
    workflowDiagramSymbol: ProcessActionDefinitionDto.WorkflowDiagramSymbolEnum;
}
export namespace ProcessActionDefinitionDto {
    export type WorkflowDiagramSymbolEnum = 'TERMINAL' | 'PROCESS' | 'ARROW' | 'DECISION' | 'INPUT_OUTPUT' | 'ON_PAGE_CONNECTOR' | 'OFF_PAGE_CONNECTOR' | 'DOCUMENT' | 'MULTIPLE_DOCUMENTS' | 'PREPARATION' | 'MANUAL_OPERATION' | 'SUMMONING_JUNCTION' | 'MERGE' | 'COLLATE' | 'SORT' | 'OR' | 'LOOP_LIMIT' | 'DELAY' | 'INTERNAL_STORAGE' | 'DATA_STORAGE' | 'DATABASE' | 'DISPLAY' | 'HARD_DISK' | 'ALTERNATE_PROCESS';
    export const WorkflowDiagramSymbolEnum = {
        Terminal: 'TERMINAL' as WorkflowDiagramSymbolEnum,
        Process: 'PROCESS' as WorkflowDiagramSymbolEnum,
        Arrow: 'ARROW' as WorkflowDiagramSymbolEnum,
        Decision: 'DECISION' as WorkflowDiagramSymbolEnum,
        InputOutput: 'INPUT_OUTPUT' as WorkflowDiagramSymbolEnum,
        OnPageConnector: 'ON_PAGE_CONNECTOR' as WorkflowDiagramSymbolEnum,
        OffPageConnector: 'OFF_PAGE_CONNECTOR' as WorkflowDiagramSymbolEnum,
        Document: 'DOCUMENT' as WorkflowDiagramSymbolEnum,
        MultipleDocuments: 'MULTIPLE_DOCUMENTS' as WorkflowDiagramSymbolEnum,
        Preparation: 'PREPARATION' as WorkflowDiagramSymbolEnum,
        ManualOperation: 'MANUAL_OPERATION' as WorkflowDiagramSymbolEnum,
        SummoningJunction: 'SUMMONING_JUNCTION' as WorkflowDiagramSymbolEnum,
        Merge: 'MERGE' as WorkflowDiagramSymbolEnum,
        Collate: 'COLLATE' as WorkflowDiagramSymbolEnum,
        Sort: 'SORT' as WorkflowDiagramSymbolEnum,
        Or: 'OR' as WorkflowDiagramSymbolEnum,
        LoopLimit: 'LOOP_LIMIT' as WorkflowDiagramSymbolEnum,
        Delay: 'DELAY' as WorkflowDiagramSymbolEnum,
        InternalStorage: 'INTERNAL_STORAGE' as WorkflowDiagramSymbolEnum,
        DataStorage: 'DATA_STORAGE' as WorkflowDiagramSymbolEnum,
        Database: 'DATABASE' as WorkflowDiagramSymbolEnum,
        Display: 'DISPLAY' as WorkflowDiagramSymbolEnum,
        HardDisk: 'HARD_DISK' as WorkflowDiagramSymbolEnum,
        AlternateProcess: 'ALTERNATE_PROCESS' as WorkflowDiagramSymbolEnum
    };
}


