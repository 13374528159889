/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SkillPojo { 
    id?: number;
    name?: string;
    type?: SkillPojo.TypeEnum;
}
export namespace SkillPojo {
    export type TypeEnum = 'BEHAVIOURAL' | 'COGNITIVE' | 'TECHNICAL' | 'INDUSTRY_BASED';
    export const TypeEnum = {
        Behavioural: 'BEHAVIOURAL' as TypeEnum,
        Cognitive: 'COGNITIVE' as TypeEnum,
        Technical: 'TECHNICAL' as TypeEnum,
        IndustryBased: 'INDUSTRY_BASED' as TypeEnum
    };
}


