<div class="card mb-4 pt-4 pb-2 px-2 col-12">
    <form [formGroup]="form" (ngSubmit)="filter()">
        <div class="row">
            <div class="col-md-6" style="align-self: center; font-size: 1rem">
                <b>UAT Days</b>
            </div>
            <div class="col-md-3 ms-auto">
                <input
                        formControlName="keyword"
                        type="text"
                        class="form-control"
                        placeholder="...search keyword"
                />
            </div>

            <div class="d-md-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <span class="h6 mt-2">{{ 'Total records' }}: <span>{{ uatSchedules.length | number }}</span></span>
                </div>
                <div class="col-md-6 d-flex justify-content-end">
                    <!--                <page-size></page-size>-->
                </div>
            </div>
        </div>
    </form>
    <div class="card" style="border: 0.2px solid #ececec">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col">S/N</th>
                        <th scope="col">UAT Name</th>
                        <th scope="col">Use Case Set</th>
                        <th scope="col">PU Set</th>
                        <th scope="col">Scheduled Date</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <ng-container *ngIf="true; else stillLoading">
                        <ng-container *ngIf="scheduleView?.length > 0; else NoDataToDisplay">
                            <tbody>
                            <tr
                                    class="justify-content-center"
                                    *ngFor="let entry of scheduleView; let i = index">
                                <td data-label="S/N">{{ (checklistPage * itemsPerPage - itemsPerPage) + (i + 1) }}</td>
                                <td data-label="UAT Name">{{ entry?.uatName }}</td>
                                <td>{{ (entry?.useCaseSet.length) || '0' }} Use Cases
                                    <span *ngIf="entry?.useCaseSet.length > 0" [placement]="'right'"
                                          [tooltip]="useCaseTooltipTemplate">
                                                <span class="iconify text-black pointer"
                                                      data-icon="material-symbols:info"> </span>
                                            </span>
                                    <ng-template #useCaseTooltipTemplate>
                                        <div *ngFor="let unit of entry.useCaseSet; let i = index">
                                            {{ i + 1 }}. {{ unit.name }}
                                        </div>
                                    </ng-template>
                                </td>
                                <td (click)="viewProductionUnits(entry?.puSet)">
                                    <u class="text-primary pointer">
                                        {{ (entry?.puSet.length) + ' PU' + (entry?.puSet.length === 1 ? '' : 's') || '-' }}
                                    </u>
                                </td>
                                <td data-label="Scheduled Date">{{ entry?.uatScheduledDate | datetime }}</td>
                                <td data-label="Action">
                                    <div style="display: flex; gap: 0.4rem; justify-content: end;">
                                        <span (click)="edit(i)">
                                            <i class="iconify pointer" data-icon="tabler:edit"
                                               style="height: 1.25rem;width: 1.25rem;">
                                        </i>
                                        </span>
                                        <span (click)="remove(i)">
                                            <i class="iconify pointer"
                                               data-icon="material-symbols:delete-outline"
                                               style="color: red;height: 1.25rem;width: 1.25rem;"
                                            >
                                            </i>
                                        </span>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
            <div
                    *ngIf="uatSchedules?.length > 10"
                    class="row py-2 mt-4"
                    [ngClass]="{ 'disabled-table': false }"
            >
                <div class="col-12 d-flex justify-content-end">
                    <pagination
                            (pageChanged)="getData($event.page)"
                            [boundaryLinks]="true"
                            [itemsPerPage]="itemsPerPage"
                            [maxSize]="10"
                            [rotate]="true"
                            [totalItems]="uatSchedules?.length"
                            firstText="&laquo;"
                            lastText="&raquo;"
                            nextText="&rsaquo;"
                            previousText="&lsaquo;"
                    ></pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #NoDataToDisplay>
    <tr>
        <td class="text-center" colspan="100%">NO DATA</td>
    </tr>
</ng-template>

<ng-template #stillLoading>
    <tr>
        <td colspan="100%">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </td>
    </tr>
</ng-template>