import {NgModule} from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoggedInGuard} from "./shared/guards/logged-in.guard";
import {ManageTicketsComponent} from "./pages/intranet/tickets/manage-tickets/manage-tickets.component";
import {DashboardLayoutComponent} from "./layout/dashboard-layout/dashboard-layout.component";
import {ExtranetLayoutComponent} from "./layout/extranet-layout/extranet-layout.component";
import {AccountMembershipPojo} from "../../sdk/customer-fulfillment-api-sdk";
import {ForbiddenComponent} from "./pages/extranet/forbidden/forbidden.component";

const routes: Routes = [
    {
        path: '',
        component: ExtranetLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/extranet/report-issue/report-issue.module').then((m) => m.ReportIssueModule)
            },
            {
                path: 'view-issue',
                loadChildren: () => import('./pages/extranet/view-issue/view-issue.module').then((m) => m.ViewIssueModule)
            },
            {
                path: 'customer-ticket',
                loadChildren: () => import('./pages/extranet/tickets/tickets.module').then((m) => m.TicketsModule)
            }
        ],
        data: {
            showPageHeader: true,
            showPageFooter: false,
            showExecution: false,
            showNav: true,
            isExtranet: true
        },
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent,
        pathMatch: 'full',
        data: {
            isExtranet: false
        }
    },
    {
        path: 'dashboard',
        canActivate: [LoggedInGuard],
        component: DashboardLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/intranet/main-dashboard/main-dashboard.module').then((m) => m.MainDashboardModule)
            },
            {
                path: 'tickets',
                data: {showExecution: true},
                loadChildren: () => import('./pages/intranet/tickets/tickets.module').then((m) => m.TicketsModule)
            },
            {
                path: 'requests',
                data: {
                    showPlatformSwitch: true,
                    showExecution: true
                },
                loadChildren: () => import('./pages/intranet/requests/requests.module').then((m) => m.RequestsModule)
            },
            {
                path: 'master-records',
                data: {showPlatformSwitch: true, showExecution: true},
                loadChildren: () => import('./pages/intranet/master-records/master-records.module').then((m) => m.MasterRecordsModule)
            },
            {
                path: 'main-dashboard',
                data: {showPlatformSwitch: true},
                loadChildren: () => import('./pages/intranet/main-dashboard/main-dashboard.module').then((m) => m.MainDashboardModule)
            },
            {
                path: 'audit-trail',
                loadChildren: () => import('./pages/intranet/audit-trail/audit-trail.module').then((m) => m.AuditTrailModule),
                data: {
                    permissions: [
                        {
                            permissions: [AccountMembershipPojo.PermissionsEnum.ViewLog]
                        }
                    ],
                    showExecution: true
                }
            },
            {
                path: 'process',
                data: {showPlatformSwitch: true, showExecution: true},
                loadChildren: () => import('./pages/intranet/process/compliance.module').then((m) => m.ComplianceModule)
            },
            {
                path: 'execution',
                data: {showPlatformSwitch: true, showExecution: true},
                loadChildren: () => import('./pages/intranet/execution/execution.module').then((m) => m.ExecutionModule)
            },
            {
                path: 'execution/scoreboard',
                data: {showPlatformSwitch: false, showExecution: true},
                loadChildren: () => import('./pages/intranet/execution/scoreboard/scoreboard.module').then((m) => m.ScoreboardModule)
            },
            {
                path: 'user-management',
                // canActivate: [IsAdminGuard],
                data: {
                    showPlatformSwitch: false,
                    showExecution: true,
                    permissions: [
                        {
                            permissions: [AccountMembershipPojo.PermissionsEnum.ViewUser]
                        }
                    ]

                },
                loadChildren: () => import('./pages/intranet/user-management/user-management.module').then((m) => m.UserManagementModule)
            },
            {
                path: 'delegation',
                data: {showPlatformSwitch: true},
                loadChildren: () => import('./pages/intranet/delegation/delegation.module').then((m) => m.DelegationModule)
            },
            {
                path: 'profile-management',
                data: {showPlatformSwitch: false},
                loadChildren: () => import('./pages/intranet/profile-management/profile-management.module').then((m) => m.ProfileManagementModule)
            },
            {
                path: 'analytics-and-reporting',
                data: {showPlatformSwitch: false},
                loadChildren: () => import('./pages/intranet/analytics-and-reporting/analytics-and-reporting-routing.module').then((m) => m.AnalyticsAndReportingRoutingModule)
            },
            {
                path: 'work-process',
                data: {showPlatformSwitch: false},
                loadChildren: () => import('./pages/intranet/work-process/work-process.module').then((m) => m.WorkProcessModule)
            },
            {
                path: 'forbidden',
                component: ForbiddenComponent,
            }
        ],
        data: {
            showPageHeader: true,
            showPageFooter: false,
            showNav: true,
            isExtranet: false
        },
    },
    {
        path: 'manage/:id',
        component: ManageTicketsComponent,
    },
    {
        path: '**',
        redirectTo: '/'
    }
];

const routerOptions: ExtraOptions = {
    // enableTracing: true, // Enable tracing
};

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
